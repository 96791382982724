import React from 'react';
import Banners from '../../../../img/banner-like-her';
import useHandleCrossBanner, {
    ICrossBannersData,
} from 'components/atomsKit/CrossBanners/useHandleCrossBanner';

interface ICrossBannerLike {
    onTouchStart: () => void;
    handleTouchMove: (e: React.TouchEvent<HTMLDivElement>, value1: number, value2: number) => void;
    handleTouchEnd: (value: number, isBanner: boolean) => void;
    nextCardId: number;
    className: string;
    item: ICrossBannersData;
}

export default function CrossBannerLike({
    onTouchStart,
    handleTouchMove,
    handleTouchEnd,
    nextCardId,
    className,
    item,
}: ICrossBannerLike) {
    const { checkNationalitySite, getCachedImages, handleBannerClick } = useHandleCrossBanner();
    if (!item) return null;
    const { target_site_id, log_id } = item;
    const nationality = checkNationalitySite(target_site_id);
    const images = getCachedImages(log_id, nationality, 'like');

    return (
        <div
            className={className}
            id={`${item?.target_site_id}`}
            style={{
                backgroundImage: `url(${Banners.images[images[1]]})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
            }}
            onTouchStart={onTouchStart}
            onClick={() => handleBannerClick(item)}
            onTouchMove={(e) => handleTouchMove(e, item?.target_site_id, nextCardId)}
            onTouchEnd={() => handleTouchEnd(item?.target_site_id, true)}
        ></div>
    );
}
