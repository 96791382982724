export const selectIsLoggedIn = (state) => state?.auth?.isLoggedIn;

export const selectUser = (state) => state?.auth?.user;

export const selectIsRefreshing = (state) => state?.auth?.isRefreshing;

export const selectError = (state) => state?.auth?.error;

export const selectMyProfile = (state) => state?.auth?.myProfile;
export const selectInfo = (state) => state?.auth?.info;
export const selectPrice = (state) => state?.auth?.user?.prices;
export const selectRoles = (state) => state?.auth?.roles;
export const selectTail = (state) => state?.auth?.tail;
export const selectIsFBConversion = (state) => state?.auth?.isFBConversion;
export const selectDocs = (state) => state?.auth?.docsState;
export const selectUserEventId = (state) => state?.auth?.userEventId;

export const isLoadingAuth = (state) => state?.auth?.isLoading;
