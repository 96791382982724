import React from 'react';
import styles from './CrossBannerOnlineNow.module.scss';
import Banners from '../../../../img/banner-online-now';
import useHandleCrossBanner, {
    ICrossBannersData,
} from 'components/atomsKit/CrossBanners/useHandleCrossBanner';

const CrossBannerOnlineNow = (props: ICrossBannersData) => {
    const { target_site_id, log_id } = props;
    const { checkNationalitySite, getCachedImages, handleBannerClick } = useHandleCrossBanner();
    const nationality = checkNationalitySite(target_site_id);
    const images = getCachedImages(log_id, nationality, 'online-now');
    return (
        <div>
            <div className={styles.online_now_cross_banner_item}>
                <div className={styles.online_now_cross_banner_gallery}>
                    {Array.isArray(images) &&
                        images.map((imageKey, index) => {
                            const imageSrc = Banners.images[imageKey];
                            return imageSrc ? (
                                <img
                                    key={index}
                                    src={imageSrc}
                                    alt={`${nationality} girl ${index + 1}`}
                                />
                            ) : null;
                        })}
                </div>
                <div className={styles.online_now_cross_banner_bottom}>
                    <button
                        type="button"
                        className={styles.online_now_cross_banner_button}
                        onClick={() => handleBannerClick(props)}
                    >
                        <span className={styles.online_now_cross_banner_button_text}>
                            Try {nationality} Chatting
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CrossBannerOnlineNow;
