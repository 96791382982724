import React, { useState } from 'react';
import styles from './ConfirmTerms.module.scss';
import bannersIMG from '../../../../img/logo';
import { IConfirmTermsProps } from './ConfirmTerms.interface';
import classes from '../../../../pages/LoginPage/LoginPage.module.scss';
import { CheckV } from 'components/atomsKit/Elements';
import useMain from '../../../../pages/LoginPage/useLoginPage';

export default function ConfirmTerms({
    crossBannersData,
    closeConfirmTermModal,
}: IConfirmTermsProps) {
    const [isCheckedTerms, setIsCheckedTerms] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const { openPolicy } = useMain();
    const openNewSite = () => {
        if (!isCheckedTerms) {
            return setErrorText('Please accept our Terms and Conditions');
        }

        if (crossBannersData?.url?.length) window.open(crossBannersData?.url, '_blank');

        closeConfirmTermModal();
    };

    const handleCheckedTerms = () => {
        setIsCheckedTerms(!isCheckedTerms);
        setErrorText('');
    };

    return (
        <div className={styles.wrapper}>
            {!!crossBannersData?.target_site_id && (
                <img
                    className={styles.logo}
                    src={bannersIMG.images[`logo_id_${crossBannersData?.target_site_id}.png`]}
                    width="135"
                    height="64"
                    alt="logo"
                />
            )}
            <h2 className={styles.title}>Please confirm our rules</h2>
            <CheckV
                value={isCheckedTerms}
                type={'confirm-terms'}
                domain={crossBannersData?.domain}
                openPolicy={openPolicy}
                onChange={handleCheckedTerms}
                className={classes.policy}
            />
            {!!+errorText?.length && <div className={styles.error_text}>{errorText}</div>}
            <button type="button" className={styles.button} onClick={openNewSite}>
                Continue
            </button>
            <button
                type="button"
                className={styles.cross}
                onClick={() => closeConfirmTermModal()}
            />
        </div>
    );
}
