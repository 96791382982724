import { createSlice } from '@reduxjs/toolkit';
import { searchList, setLike, userProfile, setFavorite } from './operations';

import { updateStatusOnline } from 'utils/common';

const girlsListInitialState = {
    cards: [],
    userProfile: null,
    isLoading: false,
    lastBannerIndex: 0,
};

const girlsListSlice = createSlice({
    name: 'girlsList',
    initialState: girlsListInitialState,

    reducers: {
        updateStatusOnlineGirlList(state, action) {
            const data = state.cards;
            if (data) {
                state.cards = updateStatusOnline(action.payload, data);
            }
        },
        updateStatusOnlineProfileCard(state, action) {
            const data = state?.userProfile?.profile?.result?.profile;
            if (data) {
                state.userProfile.profile.result.profile = updateStatusOnline(action.payload, data);
            }
        },
    },

    extraReducers: {
        [searchList.pending](state, action) {
            if (action.meta.arg.pagination === 1) {
                state.cards = [];
                // state.isLoading = true;
            }
            state.userProfile = null;
        },
        [searchList.fulfilled](state, action) {
            state.isLoading = false;
            const { res, params, type, crossBanners } = action.payload;
            const bannerFrequency =
                type === 'online'
                    ? (crossBanners[0]?.show_in?.online_now ?? 0)
                    : (crossBanners[0]?.show_in?.search ?? 0);
            const updatedList =
                params.pagination === 1 ? res : [...(state.cards || []), ...(res || [])];
            let bannerIndex = params.pagination === 1 ? bannerFrequency : state?.lastBannerIndex;

            if (bannerFrequency) {
                const bannersToInsert = Math.max(1, Math.floor(res?.length / bannerFrequency));
                for (let i = 0; i < bannersToInsert; i++) {
                    if (bannerIndex && bannerIndex <= updatedList.length) {
                        const bannerToInsert = crossBanners[i];

                        if (bannerToInsert) {
                            updatedList.splice(bannerIndex, 0, bannerToInsert);
                            bannerIndex += bannerFrequency;
                        }
                    }
                }
            }

            state.cards = updatedList;
            state.lastBannerIndex = bannerIndex;
        },
        [searchList.rejected](state) {
            state.isLoading = false;
        },

        [setLike.fulfilled](state, action) {
            state.cards = state.cards.map((item) =>
                item.external_id !== action.payload ? item : { ...item, liked: true },
            );
        },

        [setFavorite.fulfilled](state, action) {
            state.userProfile.profile.result.chat.female_favorite = action.payload;
        },

        [userProfile.pending](state) {
            state.userProfile = null;
            state.isLoading = true;
        },
        [userProfile.fulfilled](state, action) {
            state.isLoading = false;
            state.userProfile = action.payload;
        },
        [userProfile.rejected](state) {
            window.location.replace('/search');
            state.isLoading = false;
        },
    },
});

export const { updateStatusOnlineGirlList, updateStatusOnlineProfileCard } = girlsListSlice.actions;
export const girlsListReducer = girlsListSlice.reducer;
