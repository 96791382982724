import * as AWS from 'data/aws';
import * as MIRRORdata from 'data/mirror';
import { MIRROR } from 'data/mirrorConfig';

export const navConfig = {
    Letters: { icon: 'mail', text: 'Letters', url: 'letter?filter=inbox' },
    Search: { icon: 'search', text: 'Search', url: 'search' },
    Chat: { icon: 'chat', text: 'Chat', url: 'chat?filter=ALL' },
    Like: { icon: 'heards', text: 'Like', url: 'like' },
    Menu: { icon: 'burger', text: 'Menu', url: 'menu' },
};

export const configFilterChat = [
    { name: 'All chats', link: '/chat?filter=ALL' },
    { name: 'Unread', link: '/chat?filter=UNREAD' },
    { name: 'Favorite', link: '/chat?filter=FAVORITE' },
    { name: 'Your likes', link: '/chat?filter=LIKE' },
];

export const configFilterSendGifts = (name: any) => [
    { name: 'All', link: `/menu/${name}?filter=all` },
    { name: 'In progress', link: `/menu/${name}?filter=progress` },
    { name: 'Approved', link: `/menu/${name}?filter=approved` },
    { name: 'Declined', link: `/menu/${name}?filter=declined` },
];

export const configFilterLetters = [
    { name: 'Inbox', link: '/letter?filter=inbox' },
    { name: 'Outbox', link: '/letter?filter=outbox' },
    { name: 'Trash', link: '/letter?filter=trash' },
    { name: 'Bookmarks', link: '/letter?filter=bookmarks' },
];

export const appearanceConfig = {
    title: 'Appearance',
    list: [
        { key: 'height', name: 'Height' },
        { key: 'body_type', name: 'Body Type' },
        { key: 'hair_color', name: 'Hair Color' },
        { key: 'eye', name: 'Eyes Color' },
        { key: 'zodiac_sign', name: 'Zodiac' },
    ],
};

export const socialInfoConfig = {
    title: 'Social information',
    list: [
        { key: 'education', name: 'Education' },
        { key: 'occupation', name: 'Job or Profession' },
        { key: 'religion', name: 'Religion' },
        { key: 'marital', name: 'Marital status' },
        { key: 'children', name: 'Number of children' },
        { key: 'morechildren', name: 'Want to have more children' },
        {
            key: 'reason',
            name: 'Purpose of staying on the site',
        },
    ],
};

export const hobbyAndHabitsConfig = {
    title: 'Hobby and habits',
    list: [
        { key: 'hobbies', name: 'Hobby', isArr: true },
        { key: 'alcohol', name: 'Alcohol' },
        { key: 'smoking', name: 'Smoking' },
    ],
};

export const summaryConfig = {
    title: 'MY self-summary',
};

export const whoAmILookingConfig = {
    title: 'Who Am I looking for',
};

export const lookingOptions = [
    { value: 'Woman', label: 'Woman' },
    { value: 'Man', label: 'Man' },
];

export const mmOptions = [
    { id: 1, value: 1, label: 'Jan' },
    { id: 2, value: 2, label: 'Feb' },
    { id: 3, value: 3, label: 'Mar' },
    { id: 4, value: 4, label: 'Apr' },
    { id: 5, value: 5, label: 'May' },
    { id: 6, value: 6, label: 'June' },
    { id: 7, value: 7, label: 'July' },
    { id: 8, value: 8, label: 'Aug' },
    { id: 9, value: 9, label: 'Sept' },
    { id: 10, value: 10, label: 'Oct' },
    { id: 11, value: 11, label: 'Nov' },
    { id: 12, value: 12, label: 'Dec' },
];

export const ddOptions = () => {
    const dd = [];
    for (let i = 1; i <= 31; i++) {
        dd.push({ value: i, label: i });
    }
    return dd;
};

export const yyyyOptions = () => {
    const dd = [];
    for (let i = 1923; i <= 2005; i++) {
        dd.push({ value: i, label: i });
    }
    return dd;
};

export const menuOptions = [
    { name: 'Online now', icon: 'onlineMenu', link: 'online-now' },
    { name: 'Credits', icon: 'credits', link: 'purchase-history' },
    // { name: "Video", icon: "c3ph-video", link: "video" },
    {
        name: 'Sent gifts',
        icon: 'c3ph-gift',
        link: 'sentgifts',
        nameRules: 'isHideGift',
    },
    // { name: "Online now", icon: "", link: "online" },
    {
        name: 'Contact requests',
        icon: 'contact',
        link: 'contact-request',
        nameRules: 'isContactRequest',
        logicOperationRules: 'true',
    },
    { name: 'Block list', icon: 'block-list-p', link: 'block' },
    { name: 'FAQ', icon: 'faq_mob_menu', link: 'faq' },
    { name: 'Tutorial', icon: 'tutorial_mob_menu', link: 'tutorial' },
    { name: 'Policy', icon: 'policy_icon_mob_menu', link: 'policy' },
];

export const defaultFilters = {
    from: '',
    to: '',
    online: true,
    id: '',
    country: null,
    city: null,
    heightfrom: '',
    heightto: '',
    bodytype: null,
    eye: null,
    job: null,
    haircolor: null,
    marital: null,
    education: null,
    purpose: null,
    religion: null,
    children: null,
    whantchildren: null,
    alcohol: null,
    smoking: null,
    hasVideo: false,
};

export const defaultFiltersMyself = {
    from: '',
    to: '',
    online: true,
    id: '',
    country: null,
    city: null,
    heightfrom: '',
    heightto: '',
    bodytype: null,
    eye: null,
    job: null,
    haircolor: null,
    marital: null,
    education: null,
    purpose: null,
    religion: null,
    children: null,
    whantchildren: null,
    alcohol: null,
    smoking: null,
};

const transform = (item: any, id?: number) => {
    return id ? { value: item, label: item, id: id } : { value: item, label: item };
};

const transformHobby = (items: any) => {
    return items?.map((item: any) => ({
        id: item?.id,
        value: item?.hobbie,
        label: item?.hobbie,
    }));
};

export const defaultFiltersMyselfFunc = (
    user_detail: any,
    user_reference: any,
    user_hobby: any,
) => ({
    credits: user_detail?.credits,
    bonus_credits: user_detail?.bonus_credits,
    name: user_detail?.name,
    b_day: transform(user_detail?.b_day),
    b_month: transform(user_detail?.b_month),
    b_year: transform(user_detail?.b_year),
    country: transform(user_detail?.country_name, user_detail?.country),
    city_name: transform(user_reference?.city_name, user_reference?.city_id),
    external_id: user_detail?.external_id,
    email: user_detail?.email,
    marital: transform(user_reference?.marital, user_reference?.marital_id),
    education: transform(user_reference?.education, user_reference?.education_id),
    user_hobby: transformHobby(user_hobby),
    alcohol: transform(user_reference?.alcohol, user_reference?.alcohol_id),
    smoke: transform(user_reference?.smoke, user_reference?.smoke_id),
    summary: user_reference?.summary,
    reason: transform(user_reference?.reason, user_reference?.reason_id),
    real_name: user_detail?.real_name,
    email_confirmed: user_detail?.email_confirmed,
    phone: user_detail?.phone,
});

export const defaultFiltersGirl = (user_match: any, user_reference: any) => ({
    eye: transform(user_match?.eye, user_match?.eye_id),
    haircolor: transform(user_match?.hair, user_match?.hair_id),
    from: transform(user_match?.girls_age_from),
    to: transform(user_match?.girls_age_to),
    bodytype: transform(user_match?.body, user_match?.body_id),
    height: transform(user_match?.girls_height_to_, user_match?.girls_height_to),
    religion: transform(user_match?.religion, user_match?.religion_id),
    marital: transform(user_match?.marital, user_match?.marital_id),
    children: transform(user_match?.morechildren, user_match?.morechildren_id),
    alcohol: transform(user_match?.alcohol, user_match?.alcohol_id),
    smoking: transform(user_match?.smoke, user_match?.smoke_id),
    looking: user_reference?.looking,
});

export const configForEditProfile = (valuesFilterMySelf: any, valuesFilterGirl: any) => {
    return {
        user_detail: {
            name: valuesFilterMySelf?.name || '',
            b_day: valuesFilterMySelf?.b_day.value,
            b_month: valuesFilterMySelf?.b_month.value,
            b_year: valuesFilterMySelf?.b_year.value,
            country: valuesFilterMySelf?.country.id || 0,
            external_id: valuesFilterMySelf?.external_id || 0,
            email: valuesFilterMySelf?.email || '',
            real_name: valuesFilterMySelf?.real_name || '',
            email_confirmed: valuesFilterMySelf?.email_confirmed,
            phone: valuesFilterMySelf?.phone || '',
        },
        user_reference: {
            looking: valuesFilterGirl?.looking || '',
            city_id: valuesFilterMySelf?.city_name?.id || 0,
            marital_id: valuesFilterMySelf?.marital?.id || 0,
            education_id: valuesFilterMySelf?.education?.id || 0,
            alcohol_id: valuesFilterMySelf?.alcohol?.id || 0,
            smoke_id: valuesFilterMySelf?.smoke?.id || 0,
            summary: valuesFilterMySelf?.summary || '',
            reason_id: valuesFilterMySelf?.reason?.id || 0,
        },
        user_hobby: valuesFilterMySelf?.user_hobby?.map((item: any) => ({
            hobby_id: item?.id || 0,
        })),
        user_match: {
            eye_id: valuesFilterGirl?.eye?.id || 0,
            hair_id: valuesFilterGirl?.haircolor?.id || 0,
            girls_age_from: valuesFilterGirl?.from?.value || 0,
            girls_age_to: valuesFilterGirl?.to?.value || 0,
            body_id: valuesFilterGirl?.bodytype?.id || 0,
            girls_height_to: valuesFilterGirl?.height?.id || 0,
            religion_id: valuesFilterGirl?.religion?.id || 0,
            marital_id: valuesFilterGirl?.marital?.id || 0,
            morechildren_id: valuesFilterGirl?.children?.id || 0,
            alcohol_id: valuesFilterGirl?.alcohol?.id || 0,
            smoke_id: valuesFilterGirl?.smoking?.id || 0,
        },
    };
};

export const uploadFileNavConfig = [
    {
        id: 1,
        imgSelected: 'attach-photo-ico-a',
        img: 'attach-photo-ico',
        selected: true,
        nameCredit: 'photos',
        type: 'SENT_IMAGE',
        original: 'link',
        labelImage: 'link',
        accept: 'image/jpeg, image/jpg, image/png',
        AWS: AWS.IMG_BUCKET,
        creditInfo: 'SENT_IMAGE',
    },
    {
        id: 2,
        imgSelected: 'attach-video-ico-a',
        img: 'attach-video-ico',
        selected: false,
        nameCredit: 'videos',
        type: 'SENT_VIDEO',
        original: 'link',
        accept: 'video/*',
        AWS: AWS.VIDEO_BUCKET,
        labelImage: 'thumb_link',
        creditInfo: 'SENT_VIDEO_MAIL_NEW',
    },
    {
        id: 3,
        imgSelected: 'attach-audio-ico-a',
        img: 'attach-audio-ico',
        selected: false,
        nameCredit: 'audios',
        type: 'SENT_AUDIO',
        AWS: AWS.AUDIO_BUCKET,
        accept: 'audio/mp3, audio/wav, audio/mpeg, audio/x-m4a, audio/ogg',
        creditInfo: 'SENT_AUDIO_MAIL_NEW',
    },
];

export const linkConfig = [
    {
        links: [
            {
                text: 'International dating',
                link: 'https://www.sofiadate.com/international-dating/',
            },
            {
                text: 'Professional Dating',
                link: 'https://www.sofiadate.com/professional-dating/',
            },
            {
                text: 'Senior dating',
                link: 'https://www.sofiadate.com/senior-dating/',
            },
            {
                text: 'Anonymous dating',
                link: 'https://www.sofiadate.com/anonymous-dating/',
            },
        ],
    },
    {
        links: [
            {
                text: 'Over 40 dating',
                link: 'https://www.sofiadate.com/dating-over-40/',
            },
            {
                text: 'Over 50 dating',
                link: 'https://www.sofiadate.com/dating-over-50/',
            },
            {
                text: 'Over 60 dating',
                link: 'https://www.sofiadate.com/dating-over-60/',
            },
            {
                text: 'Widower dating',
                link: 'https://www.sofiadate.com/dating-a-widower/',
            },
        ],
    },
    {
        links: [
            {
                text: 'Childfree dating',
                link: 'https://www.sofiadate.com/childfree-dating/',
            },
            {
                text: 'Parents dating',
                link: 'https://www.sofiadate.com/single-parent-dating/',
            },
            {
                text: 'Divorced dating',
                link: 'https://www.sofiadate.com/divorced-dating/',
            },
        ],
    },
    {
        links: [
            {
                text: 'Catholic dating',
                link: 'https://www.sofiadate.com/catholic-dating/',
            },
            {
                text: 'Online chat dating',
                link: 'https://www.sofiadate.com/online-chat-dating/',
            },
        ],
    },

    {
        links: [
            {
                text: 'International chat',
                link: 'https://www.sofiadate.com/foreign-chat/',
            },
            {
                text: 'Christian chat',
                link: 'https://www.sofiadate.com/christian-chat/',
            },
        ],
    },
];

export const LINK_TERMS = 'terms-and-conditions';
export const LINK_PRIVACY = 'privacy-policy';
export const LINK_COOKIE = 'cookie-policy';
export const LINK_STANDARDS = 'community-standards';
export const LINK_RETURN_REFUND = 'return-and-refund-policy';
export const LINK_AFFILIATES = 'affiliates';
export const LINK_AUTOMATIC_TOP_UP = 'automatic-top-up';
export const LINK_DISPUTE_MANAGEMENT = 'dispute-management';

export const policyConfigLoginPage = [
    { text: 'Cookie policy', seo_url: LINK_COOKIE },
    { text: 'Privacy Policy', seo_url: LINK_PRIVACY },
    { text: 'Terms and Conditions', seo_url: LINK_TERMS },
    { text: 'Refund Policy', seo_url: LINK_RETURN_REFUND },
    { text: 'For Affiliates', seo_url: LINK_AFFILIATES },
    { text: 'Community Standards', seo_url: LINK_STANDARDS },
    { text: 'Automatic Top-up', seo_url: LINK_AUTOMATIC_TOP_UP },
    { text: 'Online Dispute Resolution', seo_url: LINK_DISPUTE_MANAGEMENT },
];

export const policyLinkConfig = [
    { text: 'Cookie policy', seo_url: LINK_COOKIE },
    { text: 'Privacy Policy', seo_url: LINK_PRIVACY },
    { text: 'Terms and Conditions', seo_url: LINK_TERMS },
    { text: 'Refund Policy', seo_url: LINK_RETURN_REFUND },
    { text: 'Community Standards', seo_url: LINK_STANDARDS },
    { text: 'Automatic Top-up', seo_url: LINK_AUTOMATIC_TOP_UP },
    { text: 'Online Dispute Resolution', seo_url: LINK_DISPUTE_MANAGEMENT },
];

export const POLICY_PAGE_WITH_200 = [LINK_TERMS, LINK_RETURN_REFUND];

export const PRODUCT_VERSION =
    MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].PRODUCT_VERSION || '0.1.1';

export const transactionContentConfig = (action: any, info: any) => {
    const config = {
        NEW_GIRL: {
            icon: 'pin-lightgreen',
            text: `New member’s joined the site`,
        },
        VIEW_PROFILE: {
            icon: 'c4-viwed',
            text: `${info} viewed your profile`,
        },
        MAYBE_APPEAL: {
            icon: 'c4-appeal-ico',
            text: `Maybe ${info} appeals to you`,
        },
        POSSIBLE_MATCH: {
            icon: 'c4-match-ico',
            text: `We’ve found the ideal match for you`,
            isDoubleShow: true,
        },
        NO_CHAT: {
            icon: 'c4-no-chats-ico',
            text: `${info} has no chats now. Wanna say hi?`,
        },
        MAYBE_CHAT: {
            icon: 'c4-want-chat-ico',
            text: `Maybe you want to chat with ${info}?`,
        },
        STRANGER: {
            icon: 'c4-want-chat-ico',
            text: `This member view your profile`,
        },
        LIKE: {
            icon: 'c4-like',
            text: `${info} liked your profile`,
        },
        SEARCH_PARTNER: {
            icon: 'c4-searching-ico',
            text: `${info} is searching for a partner`,
        },
        PROFILE_UPDATE: {
            icon: 'profile-ico-lightblue',
            text: `${info} has updated content in her profile.`,
        },
        ONLINE_NOW: {
            icon: 'c4-online-ico',
            text: `${info} is online now! Maybe you want to say hi?`,
        },
        MATCH: {
            icon: 'heart-chained',
            text: `You like each other`,
            isDoubleShow: true,
            bgColor: 2,
            timeOff: true,
        },
        mail: {
            icon: 'c4-letter',
            text: `${info} sent you a letter`,
        },
        message: {
            bgColor: 1,
        },
        liked: {
            icon: 'c4-like',
        },
    };
    return config[action as keyof typeof config] as any;
};

export function getLegalEntityInfo() {
    if (
        MIRRORdata.SITE_SEMPITERNA.includes(
            MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].SITE_ID,
        )
    ) {
        return MIRRORdata.INFO_SEMPITERNA;
    } else if (
        MIRRORdata.SITE_ASTRASOFT.includes(
            MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].SITE_ID,
        )
    ) {
        return MIRRORdata.INFO_ASTRASOFT;
    } else if (
        MIRRORdata.SITE_KRISTALDIGITAL.includes(
            MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR].SITE_ID,
        )
    ) {
        return MIRRORdata.INFO_KRISTALDIGITAL;
    } else {
        return '';
    }
}

export const configServiceBtn = [
    {
        includePatch: '/search',
        action: 'open filter',
        icon: 'filter',
        text: 'Filters',
    },
    {
        includePatch: '/profile-woman',
        action: '/search',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
    {
        includePatch: '/chat/',
        action: '/chat?filter=ALL',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
    {
        includePatch: [
            '/profile',
            '/menu/policy',
            '/menu/tutorial',
            '/menu/faq',
            '/menu/block',
            '/menu/sentgifts',
        ],
        action: '/menu',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
    {
        includePatch: 'profileId',
        action: '/letter',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
    {
        includePatch: '/gifts',
        action: '',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
    {
        includePatch: 'cart',
        action: '/gifts',
        icon: 'arrowToLeft',
        text: 'Go back',
    },
];

export const LOOKING_GENDER_MAN = [
    {
        id: 'ALL',
        value: 'I`m a man looking for all',
        label: 'I`m a man looking for all',
        gender: 'I`m a man looking for all',
        status: 1,
    },
    {
        id: 0,
        value: 'I`m a man looking for woman',
        label: 'I`m a man looking for woman',
        gender: 'I`m a man looking for woman',
        status: 1,
    },
    {
        id: 1,
        value: 'I`m a man looking for man',
        label: 'I`m a man looking for man',
        gender: 'I`m a man looking for man',
        status: 1,
    },
];

export const LOOKING_GENDER_WOMAN = [
    {
        id: 'ALL',
        value: 'I`m a woman looking for all',
        label: 'I`m a woman looking for all',
        gender: 'I`m a woman looking for all',
        status: 1,
    },
    {
        id: 1,
        value: 'I`m a woman looking for man',
        label: 'I`m a woman looking for man',
        gender: 'I`m a woman looking for man',
        status: 1,
    },
    {
        id: 0,
        value: 'I`m a woman looking for woman',
        label: 'I`m a woman looking for woman',
        gender: 'I`m a woman looking for woman',
        status: 1,
    },
];

export const PAGE_PERFORMANCE_METRICS = [
    {
        type: 'histogram',
        name: 'page_performance',
        description: 'Page Performance',
    },
];

export const CurrencyEnum = {
    USD: 'USD',
    EUR: 'EUR',
    GBP: 'GBP',
};
