import React, { useState } from 'react';
import styles from './VerificationAlert.module.scss';
import VerifyBlock from './VerifyBlock';
import FAQ from './FAQ';
import UserIdBlock from './UserIdBlock';
import UserSelfieBlock from './UserSelfieBlock';
import SuccessBlock from './SuccessBlock';
import { useDispatch, useSelector } from 'react-redux';
import { IIdentificationAlertProps } from './index.interface';
import UserOnlySelfieBlock from './UserOnlySelfieBlock';
import { DocsTypes } from './constants';
import { selectDocs } from '../../../../redux/auth/selectors';
import { getIsOpenVerificationModal } from '../../../../redux/interface/selectors';
import { CloseButton, ModalSupport } from 'components/atomsKit/Elements';
import useMain from '../../../../pages/LoginPage/useLoginPage';
import { logOut } from '../../../../redux/auth/operations';
import { AppDispatch } from '../../../../redux/store';

export default function VerificationAlert({
    deleteUploadedFileIdentityVerification,
    handleInputIdentityVerification,
    closeIdentityVerificationModal,
    onSubmitIdentityVerification,
    onSubmitIdentityVerificationProfile,
    isWarning,
    isDataPassed,
    isBlockClosing,
}: IIdentificationAlertProps) {
    const docsState = useSelector(selectDocs);
    const isProfileIdentification = useSelector(getIsOpenVerificationModal);
    const dispatch = useDispatch<AppDispatch>();
    const { setIsOpenModalSupport, isOpenModalSupport } = useMain();
    const [isUserIdFileApproved, setIsUserIdFileApproved] = useState<boolean>(
        docsState?.approvedDocs && docsState.approvedDocs.includes(DocsTypes.VALID_ID),
    );
    const [isUserSelfieFileApproved, setIsUserSelfieFileApproved] = useState<boolean>(
        docsState?.approvedDocs && docsState.approvedDocs.includes(DocsTypes.SELFIE),
    );
    const [isUserProfileVerified, setIsUserProfileVerified] = useState<boolean>(
        docsState?.approvedDocs && docsState.approvedDocs.includes(DocsTypes.SELFIE_VERIFY),
    );
    const [currentBlock, setCurrentBlock] = useState<number>(1);
    const [isVerificationStarted, setIsVerificationStarted] = useState(false);

    const handleLogout = () => {
        dispatch(logOut());
    };

    const handleChangeBlock = (number?: number) => {
        if (number && number !== currentBlock) {
            setCurrentBlock(number);
        } else if (!number) {
            const blockNumber = isProfileIdentification ? getBlockVerification() : getBlock(true);
            if (blockNumber !== currentBlock) {
                setCurrentBlock(blockNumber);
            }
        }
    };

    const handleStartVerification = () => {
        setIsVerificationStarted(true);
        const blockNumber = isProfileIdentification ? getBlockVerification() : getBlock(true);
        handleChangeBlock(blockNumber);
    };

    const getBlock = (isStarted?: boolean) => {
        const userIdFileExists = !!docsState?.userIdFile?.name || isUserIdFileApproved;
        const userSelfieFileExists = !!docsState?.userSelfieFile?.name || isUserSelfieFileApproved;

        if (userIdFileExists && userSelfieFileExists && (isVerificationStarted || isStarted)) {
            return 1;
        } else if (userIdFileExists && (isVerificationStarted || isStarted)) {
            return 4;
        } else if (userSelfieFileExists && (isVerificationStarted || isStarted)) {
            return 3;
        } else if (!userIdFileExists && !userSelfieFileExists) {
            return 3;
        } else {
            return 1;
        }
    };

    const getBlockVerification = () => {
        return docsState?.userOnlySelfieFile?.name ? 1 : 3;
    };

    const activeBlock = () => {
        if (+currentBlock === 1)
            return (
                <VerifyBlock
                    handleChangeBlock={handleChangeBlock}
                    onSubmit={
                        isProfileIdentification
                            ? onSubmitIdentityVerificationProfile
                            : onSubmitIdentityVerification
                    }
                    handleStartVerification={handleStartVerification}
                    isUserIdFileApproved={isUserIdFileApproved}
                    isUserSelfieFileApproved={isUserSelfieFileApproved}
                    isUserProfileVerified={isUserProfileVerified}
                />
            );
        else if (+currentBlock === 2)
            return <FAQ handleStartVerification={handleStartVerification} />;
        else if (+currentBlock === 3)
            if (isProfileIdentification) {
                return (
                    <UserOnlySelfieBlock
                        handleChangeBlock={handleChangeBlock}
                        handleInput={handleInputIdentityVerification}
                        deleteUploadedFile={deleteUploadedFileIdentityVerification}
                        isWarning={isWarning}
                        isUserProfileVerified={isUserProfileVerified}
                        setIsUserProfileVerified={setIsUserProfileVerified}
                    />
                );
            } else {
                return (
                    <UserIdBlock
                        handleChangeBlock={handleChangeBlock}
                        handleInput={handleInputIdentityVerification}
                        deleteUploadedFile={deleteUploadedFileIdentityVerification}
                        isWarning={isWarning}
                        isUserIdFileApproved={isUserIdFileApproved}
                        setIsUserIdFileApproved={setIsUserIdFileApproved}
                    />
                );
            }
        else if (+currentBlock === 4)
            return (
                <UserSelfieBlock
                    handleChangeBlock={handleChangeBlock}
                    handleInput={handleInputIdentityVerification}
                    deleteUploadedFile={deleteUploadedFileIdentityVerification}
                    isWarning={isWarning}
                    isUserSelfieFileApproved={isUserSelfieFileApproved}
                    setIsUserSelfieFileApproved={setIsUserSelfieFileApproved}
                />
            );
    };

    return (
        <div className={styles.popup_wrap}>
            <div className={`${styles.popup} ${isDataPassed ? styles.popup_success : ''}`}>
                {!isDataPassed ? (
                    <>
                        <header
                            className={`${styles.popup_header} ${isProfileIdentification ? styles.popup_header_center : ''}`}
                        >
                            {!isProfileIdentification && (
                                <button
                                    type="button"
                                    className={styles.help_button}
                                    onClick={handleLogout}
                                    data-testid="email-btn"
                                >
                                    <span>Logout</span>
                                </button>
                            )}
                            <h2 className={styles.popup_title}>Identity Verification</h2>
                            <button
                                type="button"
                                className={`${isBlockClosing ? styles.help_button : styles.help_button_with_close} ${isProfileIdentification ? styles.help_button_right : ''}`}
                                onClick={() => setIsOpenModalSupport(true)}
                                data-testid="email-btn"
                            >
                                <span>Help</span>
                            </button>
                        </header>
                        <section
                            className={`${styles.popup_body} ${+currentBlock === 2 ? styles.popup_body_faq : ''}`}
                        >
                            {activeBlock()}
                        </section>
                    </>
                ) : (
                    <SuccessBlock
                        isBlockClosing={isBlockClosing}
                        onClose={closeIdentityVerificationModal}
                        handleLogout={handleLogout}
                        openSupportModal={() => setIsOpenModalSupport(true)}
                    />
                )}

                {!isBlockClosing && (
                    <CloseButton
                        onClick={closeIdentityVerificationModal}
                        btnClasses={styles.close_btn}
                    />
                )}
                <ModalSupport
                    modalIsOpen={isOpenModalSupport}
                    closeModal={() => {
                        setIsOpenModalSupport(false);
                    }}
                />
            </div>
        </div>
    );
}
