import React from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { MIRROR } from 'data/mirrorConfig';
import {
    Button,
    SelectDropDown,
    CheckV,
    Box,
    Image,
    ModalAlert,
    InputModern,
    ModalSupport,
    GoogleAuthButton,
    ModalResetPassword,
    ModalNewPassword,
    TextNameonChat,
    Textonchat,
} from 'components/atomsKit/Elements';

import { linkConfig, policyConfigLoginPage } from 'data/config';

import { listIconDescription } from './helpers/LoginPageConfig';

import { text } from 'data/text';
import { lookingOptions, mmOptions, ddOptions, yyyyOptions } from 'data/config';
import useMain from './useLoginPage';

import { useOpenModalNewPass, useInitCheckIP } from 'hooks';

import classes from './LoginPage.module.scss';

const LoginPage = () => {
    const {
        modeLogin,
        setModeLogin,
        valueRegister,
        handleRegister,
        onSubmitFormRegister,
        onSubmitFormLogin,
        responseGoogle,
        openModalValidate,
        setOpenModalValidate,
        validateFildError,
        error,
        // goToRegistaration,
        openPolicy,
        setIsOpenModalSupport,
        isOpenModalSupport,
        isOpenResetPassModal,
        setIsOpenResetPassModal,

        recaptchaRef,
        hasCaptcha,
    } = useMain();

    const { isOpenModalNewPass, setIsOpenModalNewPass } = useOpenModalNewPass();
    useInitCheckIP();

    return (
        <>
            <button
                className={classes.btnSupport}
                onClick={() => {
                    setIsOpenModalSupport(true);
                }}
            >
                <Image name="main-support" />
            </button>
            {isOpenResetPassModal && (
                <ModalResetPassword
                    currentEmail={valueRegister?.email}
                    modalIsOpen={isOpenResetPassModal}
                    closeModal={() => setIsOpenResetPassModal(false)}
                />
            )}

            <ModalNewPassword
                modalIsOpen={isOpenModalNewPass}
                closeModal={() => setIsOpenModalNewPass(false)}
            />
            <ModalSupport
                modalIsOpen={isOpenModalSupport}
                closeModal={() => {
                    setIsOpenModalSupport(false);
                }}
            />
            <ModalAlert
                modalIsOpen={openModalValidate}
                closeModal={() => setOpenModalValidate(false)}
                onClick={() => setOpenModalValidate(false)}
                textAlertBtn="Got it"
                icon="x1"
                allertMessage={error}
            />
            <Box className={classes.wrapLoginPage}>
                <Box className={classes.header} flex align="center" justify="between">
                    <Image
                        name={`logo_${process.env.REACT_APP_MIRROR_NAME}`}
                        className={classes.logo}
                    />

                    <Box flex align="center">
                        <GoogleAuthButton responseGoogle={responseGoogle} />
                        <Button
                            margin="0 10px 0 10px"
                            text={modeLogin === 'create' ? 'Sign In' : 'Sign Up'}
                            iconSvg={'login-btn-white'}
                            className={classes.signInHeader}
                            onClick={() => {
                                setModeLogin(modeLogin === 'create' ? 'login' : 'create');
                            }}
                        />
                    </Box>
                </Box>
                <div className={classes.pictureWrap}>
                    <img
                        src={require(
                            `../../assets/img/${
                                MIRROR[process.env.REACT_APP_MIRROR_NAME].LOGIN_BG_IMG
                            }`,
                        )}
                        alt="Dynamic background"
                        className={classes.pictureImg}
                    />
                    <Box className={classes.ramp} />
                    <TextNameonChat
                        className={classes.title}
                        text={
                            text[
                                `CONNECT_WITH_NEW_PEOPLE_${
                                    MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME
                                }`
                            ]
                        }
                        size={18}
                    />
                </div>
                <Textonchat
                    className={classes.titleDescription}
                    text={
                        text[
                            `CONNECT_WITH_NEW_PEOPLE_DESCRIPTION_${
                                MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME
                            }`
                        ]
                    }
                    size={16}
                />
                <Box
                    margin="0 16px 0 16px"
                    flex
                    justify={'center'}
                    className={classes.sectionForm}
                    id="registerForm"
                >
                    {modeLogin === 'create' ? (
                        <form
                            className={classes.form}
                            onSubmit={(e) => onSubmitFormRegister(e, valueRegister)}
                        >
                            {hasCaptcha && (
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={
                                        process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
                                        '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                                    }
                                    badge="bottomleft"
                                />
                            )}
                            <TextNameonChat
                                text={text.FREE_TODAY}
                                size={18}
                                className={classes.ddd}
                            />
                            <InputModern
                                error={error && validateFildError('name')}
                                placeholder="What is your name?"
                                value={valueRegister?.name}
                                onChange={(e) => {
                                    handleRegister(e.target.value, 'name');
                                }}
                            />
                            <SelectDropDown
                                options={lookingOptions}
                                className={classes.selectSeeking}
                                value={valueRegister?.looking}
                                onChange={(value) => {
                                    handleRegister(value, 'looking');
                                }}
                                formatOptionLabel={'I am a '}
                                placeholder={'I am a..'}
                                error={error && validateFildError('looking')}
                            />
                            <InputModern
                                type="email"
                                error={error && validateFildError('email')}
                                placeholder="What is your email?"
                                value={valueRegister?.email}
                                onChange={(e) => {
                                    handleRegister(e.target.value, 'email');
                                }}
                            />
                            <InputModern
                                type="password"
                                error={error && validateFildError('password')}
                                icon=""
                                placeholder="Password (8+ characters)"
                                value={valueRegister?.password}
                                onChange={(e) => {
                                    handleRegister(e.target.value, 'password');
                                }}
                            />
                            <Box flex width100 justify={'center'} marginTop="12px">
                                <SelectDropDown
                                    className={classes.select}
                                    placeholder="MM"
                                    options={mmOptions}
                                    onChange={(value) => {
                                        handleRegister(value, 'bMonth');
                                    }}
                                    error={error && validateFildError('bMonth')}
                                />
                                <SelectDropDown
                                    className={classes.select}
                                    placeholder="DD"
                                    options={ddOptions()}
                                    onChange={(value) => {
                                        handleRegister(value, 'bDay');
                                    }}
                                    error={error && validateFildError('bDay')}
                                />
                                <SelectDropDown
                                    className={classes.select}
                                    placeholder="YYYY"
                                    options={yyyyOptions()}
                                    onChange={(value) => {
                                        handleRegister(value, 'bYear');
                                    }}
                                    error={error && validateFildError('bYear')}
                                />
                            </Box>

                            <CheckV
                                type={'login'}
                                value={valueRegister?.policy}
                                label={text.LOGIN_POLICY}
                                openPolicy={openPolicy}
                                onChange={() => {
                                    handleRegister(!valueRegister?.policy, 'policy');
                                }}
                                className={classes.policy}
                                error={error && validateFildError('policy')}
                            />

                            <Button
                                text={'Register now!'}
                                type={'submit'}
                                className={classes.btnText}
                            />
                            <Box flex align={'center'} marginTop={'10px'}>
                                <Textonchat
                                    text={'Already have an account?'}
                                    size={16}
                                    isSpan
                                    className={classes.signUpDescription}
                                />

                                <button
                                    type="button"
                                    className={classes.signInForm}
                                    onClick={() => {
                                        setModeLogin('login');
                                    }}
                                >
                                    Sign in
                                </button>
                            </Box>
                        </form>
                    ) : (
                        <form
                            className={classes.form}
                            onSubmit={(e) => onSubmitFormLogin(e, valueRegister)}
                        >
                            <TextNameonChat text={'Sign In'} size={18} />

                            <InputModern
                                type="email"
                                error={error && validateFildError('email')}
                                placeholder="What is your email?"
                                value={valueRegister?.email}
                                onChange={(e) => {
                                    handleRegister(e.target.value, 'email');
                                }}
                            />
                            <InputModern
                                type="password"
                                error={error && validateFildError('password')}
                                icon=""
                                placeholder="Password (8+ characters)"
                                value={valueRegister?.password}
                                onChange={(e) => {
                                    handleRegister(e.target.value, 'password');
                                }}
                            />

                            <Button
                                type="button"
                                borderOff
                                noBg
                                color="#9f9f9f"
                                margin="0 10px 0 10px"
                                text={'Forgot a password?'}
                                onClick={() => {
                                    setIsOpenResetPassModal(true);
                                }}
                            />

                            <Button text={'Login'} type={'submit'} className={classes.btnText} />
                            <Box flex align={'center'} marginTop={'10px'}>
                                <Textonchat
                                    text={'Don’t have a account?'}
                                    size={16}
                                    isSpan
                                    className={classes.signUpDescription}
                                />
                                <button
                                    type="button"
                                    className={classes.signInForm}
                                    onClick={() => {
                                        setModeLogin('create');
                                    }}
                                >
                                    Sign Up
                                </button>
                            </Box>
                        </form>
                    )}
                </Box>
                <Box>
                    <ul>
                        {listIconDescription[MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME]?.map(
                            (item) => (
                                <li className={classes.listIcon} key={item?.title}>
                                    <Image name={item.icon} className={classes.itemIconList} />
                                    <TextNameonChat
                                        text={item?.title}
                                        size={18}
                                        className={classes.iconTitle}
                                    />
                                    <Textonchat
                                        text={item?.decription}
                                        size={16}
                                        className={classes.iconDescription}
                                        textAlign={'center'}
                                    />
                                </li>
                            ),
                        )}
                    </ul>
                </Box>
                {process.env.REACT_APP_MIRROR_NAME !== 'avodate' &&
                    process.env.REACT_APP_MIRROR_NAME !== 'okamour' && (
                        <Box
                            width100
                            flex
                            directionColumn
                            align="center"
                            justify="center"
                            marginTop="80px"
                        >
                            <TextNameonChat
                                text={'The Most Popular Categories'}
                                size={18}
                                margin="20px 0 20px 0"
                                className={classes.iconTitle}
                            />
                            {linkConfig.map((group, index) => (
                                <Box key={index} marginBottom="20px">
                                    {group?.links?.map((item) => (
                                        <Link key={item?.text} to={item?.link}>
                                            <TextNameonChat
                                                text={item?.text}
                                                className={classes.popular}
                                                textAlign={'center'}
                                            />
                                        </Link>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    )}

                <footer className={classes.footer}>
                    <Box flex justify={'center'} align={'center'} marginBottom={'20px'}>
                        <Image name={`logo_light_${process.env.REACT_APP_MIRROR_NAME}`} />
                    </Box>
                    <Box flex justify="center" directionColumn align="center">
                        {MIRROR[process.env.REACT_APP_MIRROR_NAME].LIGAL_TEXT.map((item) => (
                            <Textonchat
                                key={item}
                                text={item}
                                size={9}
                                textAlign={'center'}
                                className={classes.footerText}
                            />
                        ))}
                        <a
                            className={classes.footerText}
                            href={`mailto:${
                                MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL
                            }`}
                        >
                            {`E-mail: ${MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL}`}
                        </a>
                        <Textonchat
                            margin="10px 0 0 0"
                            text={'You must be 18+ to use this site.'}
                            size={9}
                            className={classes.footerText}
                        />
                    </Box>

                    <Box flex align={'center'} justify={'center'} className={classes.bankWrap}>
                        {/* Mccoffe */}
                        <Image name={'bank2'} />
                        {/* SSL*/}
                        <Image name={'bank4'} />
                        {/* MasterCard */}
                        <Image name={'bank3'} />
                        {/* Visa */}
                    </Box>
                    <Box flex align={'center'} justify={'center'} className={classes.bankWrap}>
                        <Image name={'bank5'} />
                        {/* PCI */}
                        <Image name={'bank1'} />
                    </Box>

                    <Box flex justify={'center'} marginTop="20px" className={classes.socialWrap}>
                        {MIRROR[process.env.REACT_APP_MIRROR_NAME].SOCIAL_LIST?.map((item) => (
                            <Link target="_blank" to={item?.link} key={item?.link}>
                                <Image name={item?.icon} className={classes.social} />
                            </Link>
                        ))}
                    </Box>

                    <Box flex directionColumn align="center">
                        {policyConfigLoginPage?.map((item) => (
                            <Button
                                key={item?.seo_url}
                                onClick={() => openPolicy(item?.seo_url)}
                                className={classes.policyBtn}
                                noBg
                                fitContent
                                text={
                                    <Textonchat
                                        text={item?.text}
                                        size={12}
                                        className={classes.footerText}
                                    />
                                }
                            />
                        ))}
                    </Box>
                </footer>
            </Box>
        </>
    );
};

export default LoginPage;
