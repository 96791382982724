import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getCards } from 'redux/girlsList/selectors';
import { getIsOpenFilter, getSayHalloList } from 'redux/interface/selectors';
import { selectMyProfile, selectUser } from 'redux/auth/selectors';

import _ from 'lodash';

import { postRequest, getRequest } from 'utils/requests';

import { searchList, setLike } from 'redux/girlsList/operations';
import { sendMessage } from 'redux/chat/operations';

import { clickFilter, togleGlobalModal } from 'redux/interface/slice';

import { parserFilterForFetch } from 'utils/common';
import { defaultFilters, LOOKING_GENDER_MAN, LOOKING_GENDER_WOMAN } from 'data/config';

import { useDebouncedState } from 'hooks';

const useOnlinePage = () => {
    const cards = useSelector(getCards);
    const gender = useSelector(selectUser)?.user_info?.gender;
    const search_multigender = useSelector(selectUser)?.search_multigender;
    const sayHalloList = useSelector(getSayHalloList);

    const [pagination, setPagination] = useState(1);
    const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);
    const [isOpenModalPhoto, setIsOpenModalPhoto] = useState(false);
    const [dataForVideo, setDataForVideo] = useState();
    const [dataForModalPhoto, setDataForModalPhoto] = useState({
        chat_uid: undefined,
        external_id: undefined,
        item: [],
    });

    const [isOpenModalSayHello, setIsOpenModalSayHello] = useState(false);
    const [isOpenModalWink, setIsOpenModalWink] = useState(false);

    const [valuesFilter, setValuesFilter] = useState({
        ...defaultFilters,

        gender: gender === 0 ? LOOKING_GENDER_MAN[0] : LOOKING_GENDER_WOMAN[0],
    });

    const [cityOptions, setCityOptions] = useState([]);

    const [citySerch, debouncedCitySerch, setCitySerch] = useDebouncedState('');

    useEffect(() => {
        const countryId = valuesFilter?.country?.id;
        if (debouncedCitySerch.length >= 3 && valuesFilter?.country) {
            getRequest(
                `/api/user/cityList?country_id=${countryId}&city_name=${debouncedCitySerch}`,
            ).then((res) => {
                if (res?.response?.length > 0) {
                    setCityOptions(
                        res?.response?.map((item) => ({
                            ...item,
                            value: item.city_name,
                            label: item.city_name,
                        })),
                    );
                }
            });
        } else {
            if (!valuesFilter.city) {
                setCityOptions([]);
            }
        }
    }, [debouncedCitySerch]);

    const onChangeOption = (name, value) => {
        setValuesFilter({
            ...valuesFilter,
            [name]: value,
            city: name === 'country' ? null : name === 'city' ? value : valuesFilter.city,
        });
        if (name === 'country') {
            setCityOptions([]);
            setCitySerch('');
        }
    };

    const onInputChangeCity = (value) => {
        setCitySerch(value);
    };

    const isNotDefaultFilter = _.isEqual(valuesFilter, {
        ...defaultFilters,
        gender: gender === 0 ? LOOKING_GENDER_MAN[0] : LOOKING_GENDER_WOMAN[0],
    });

    const filtersOptions = {
        ...useSelector(selectMyProfile)?.referencies_list,

        gender_list: gender === 0 ? LOOKING_GENDER_MAN : LOOKING_GENDER_WOMAN,
    };
    const userExternalId = useSelector(selectMyProfile)?.user_detail?.external_id;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isOpenFilter = useSelector(getIsOpenFilter);

    useEffect(() => {
        if (gender !== undefined) {
            setPagination(1);
            dispatch(
                searchList({
                    pagination: 1,
                    filters: parserFilterForFetch(valuesFilter, search_multigender, gender, 1),
                    type: 'online',
                }),
            );
        }
    }, [valuesFilter, gender]);

    const modalFilterClose = () => {
        dispatch(clickFilter('close'));
    };

    const handleProfile = (id) => {
        navigate(`/profile-woman/${id}`);
    };

    const handleLike = (external_id) => {
        dispatch(setLike(external_id));
    };

    const handleChat = (chat_uid, id) => {
        navigate(`/chat/${chat_uid}?profile=${id}`);
    };

    const handleScroll = () => {
        setPagination(pagination + 1);
        dispatch(
            searchList({
                pagination: pagination + 1,
                filters: parserFilterForFetch(valuesFilter, search_multigender, gender, 1),
                type: 'online',
            }),
        );
    };

    const handleClearFilters = () => {
        setValuesFilter({
            ...defaultFilters,

            gender: gender === 0 ? LOOKING_GENDER_MAN[0] : LOOKING_GENDER_WOMAN[0],
        });
        setCityOptions([]);
        setCitySerch('');
    };

    const handlePlayVideo = (external_id) => {
        setDataForVideo();
        postRequest('/api/v3/search/mobile-item', {
            girl_id: external_id,
        }).then((item) => {
            getRequest('/api/v3/search/check-video').then(() => {
                getRequest(
                    `/api/video/${
                        item.response?.content?.result.find(
                            (item) => item?.content_type === 'video',
                        ).id
                    }`,
                )
                    .then((res) => {
                        if (res?.link) {
                            const profile = item?.response?.profile?.result?.profile;
                            setDataForVideo({
                                name: profile?.name,
                                city: profile?.city_name,
                                flag: profile?.country_icon,
                                age: profile?.age,
                                link: res?.link,
                            });
                            setIsOpenModalVideo(true);
                        } else {
                            dispatch(
                                togleGlobalModal({
                                    isOpen: 'open',
                                    text: 'You lack credits',
                                    btnText: 'Get credits',
                                    description:
                                        'You need to get more credits to be able to watch video',
                                }),
                            );
                        }
                    })
                    .catch(() => {
                        dispatch(
                            togleGlobalModal({
                                isOpen: 'open',
                                text: 'You lack credits',
                                btnText: 'Get credits',
                                description:
                                    'You need to get more credits to be able to watch video',
                            }),
                        );
                    });
            });
        });
    };

    const handlCardPhoto = (chat_uid, external_id, wink) => {
        setIsOpenModalPhoto(true);
        postRequest('/api/v3/search/mobile-item', {
            girl_id: external_id,
        }).then((item) => {
            setDataForModalPhoto({
                chat_uid: chat_uid,
                external_id: external_id,
                item: item?.response?.content?.result,
                wink: wink,
            });
        });
    };

    const handleCloseModalPhoto = () => {
        setIsOpenModalPhoto(false);
        setDataForModalPhoto({
            chat_uid: undefined,
            external_id: undefined,
            item: [],
        });
    };

    const closeModalVideo = () => {
        setIsOpenModalVideo(false);
    };

    const handleSayHello = () => {
        setIsOpenModalWink(true);
    };

    const handleWink = (wink, external_id) => {
        const reqBody = {
            recipient_id: external_id,
            sender_id: userExternalId,
            filename: '',
            message_content: wink?.message_content,
            message_type: 'SENT_WINK',
        };
        dispatch(sendMessage(reqBody));
        setIsOpenModalWink(false);
        setIsOpenModalSayHello(true);
        navigate(`/chat/${'null'}?profile=${external_id}`);
    };

    const handleLetters = (id) => {
        navigate(`/letter?profileId=${id}`);
    };

    const onChangeSearch = (value) => {
        onChangeOption('id', value);
    };

    return {
        cards,
        handleProfile,
        isOpenFilter,
        modalFilterClose,
        filtersOptions,
        handleLike,
        onChangeOption,
        onInputChangeCity,
        citySerch,
        cityOptions,
        valuesFilter,
        handleScroll,

        isNotDefaultFilter,
        handleClearFilters,
        handleChat,
        handlePlayVideo,
        isOpenModalVideo,
        closeModalVideo,
        dataForVideo,
        handlCardPhoto,
        isOpenModalPhoto,
        setIsOpenModalPhoto,
        handleCloseModalPhoto,
        dataForModalPhoto,
        handleWink,
        isOpenModalSayHello,
        isOpenModalWink,
        setIsOpenModalWink,
        sayHalloList,
        handleSayHello,
        search_multigender,
        handleLetters,
        onChangeSearch,
    };
};

export default useOnlinePage;
