import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import VerificationAlert from './VerificationAlert/VerificationAlert';
import useInitVerification from './VerificationAlert/useInitVerification';
import { useLocation } from 'react-router-dom';
import ModalWindowModern from 'components/atomsKit/ModalWindowModern/ModalWindowModern';
import { selectDocs } from '../../../redux/auth/selectors';

export default function ModalVerification() {
    const location = useLocation();
    const docsState = useSelector(selectDocs);
    const initVerification = useInitVerification(docsState.configID, docsState.banner);
    const {
        setModalIsActiveIdentityVerification,
        modalIsActiveIdentityVerification,
        closeIdentityVerificationModal,
        getIsNeedToVerifyDocs,
    } = initVerification;

    useEffect(() => {
        getIsNeedToVerifyDocs();
    }, [location.pathname]);

    useEffect(() => {
        setModalIsActiveIdentityVerification(docsState.isShow ? docsState.isShow : false);
    }, [docsState.isShow]);

    useEffect(() => {
        if (docsState.isBlockClosing && location.pathname !== '/chat') {
            window.location.replace('/chat');
        }
    }, [location.pathname, docsState.isBlockClosing]);

    return (
        <>
            <ModalWindowModern
                modalIsOpen={modalIsActiveIdentityVerification}
                closeModal={() => closeIdentityVerificationModal()}
                hideCloseButton
                shouldCloseOnOverlayClick={!docsState.isBlockClosing}
                zIndex={2001}
            >
                <VerificationAlert
                    {...initVerification}
                    isBlockClosing={docsState.isBlockClosing}
                />
            </ModalWindowModern>
        </>
    );
}
