import React, { MouseEventHandler, useState } from 'react';
import styles from '../VerificationAlert.module.scss';
import PropTypes from 'prop-types';
import { CloseButton } from 'components/atomsKit/Elements';

UploadedPhoto.propTypes = {
    onClose: PropTypes.func,
    image: PropTypes.object,
};
export default function UploadedPhoto({
    onClose,
    image,
}: {
    onClose: MouseEventHandler<HTMLButtonElement>;
    image: any;
}) {
    const [loadedImage, setLoadedImage] = useState<string>('');
    const handleImageUpload = () => {
        const reader = new FileReader();
        reader.onload = (e) => {
            setLoadedImage(e.target?.result as string);
        };
        reader.readAsDataURL(image);
    };

    handleImageUpload();

    return (
        <div className={styles.uploaded_photo_wrap}>
            <CloseButton onClick={onClose} btnClasses={styles.close_btn} />
            <img src={loadedImage} alt="Uploaded photo" className={styles.uploaded_photo} />
        </div>
    );
}
