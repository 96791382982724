import { useDispatch } from 'react-redux';
import { ChangeEvent, MouseEvent, useState } from 'react';
import {
    AFFILIATES_CONTACTS_MAX_LENGTH,
    AFFILIATES_MSG_MAX_LENGTH,
    AFFILIATES_NAME_MAX_LENGTH,
} from 'components/atomsKit/ModalAffiliates/constants';
import { postRequest } from '../../../utils/requests';
import { closeAffiliatesModal, togleGlobalModal } from '../../../redux/interface/slice';

export default function useInitAffiliates() {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        contacts: '',
        firstname: '',
        messageContent: '',
    });
    const [validation, setValidation] = useState({
        validationContacts: false,
        validationName: false,
        validationMessage: false,
    });

    const closeModal = () => {
        dispatch(closeAffiliatesModal());
        setForm({
            contacts: '',
            firstname: '',
            messageContent: '',
        });
    };

    const validateContacts = (value: string) => {
        return value?.length >= AFFILIATES_CONTACTS_MAX_LENGTH;
    };

    const validateName = (value: string) => {
        return value?.length >= AFFILIATES_NAME_MAX_LENGTH;
    };

    const validateMessage = (value: string) => {
        return value?.length >= AFFILIATES_MSG_MAX_LENGTH;
    };
    const handleInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value, name } = event.target;
        setForm((prev) => ({ ...prev, [name]: value }));

        if (name === 'contacts' && validateContacts(value)) {
            setValidation((prev) => ({ ...prev, validationContacts: false }));
        }
        if (name === 'firstname' && validateName(value)) {
            setValidation((prev) => ({ ...prev, validationName: false }));
        }
        if (name === 'messageContent' && validateMessage(value)) {
            setValidation((prev) => ({ ...prev, validationMessage: false }));
        }
    };

    const onSubmit = (event: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        event?.preventDefault();
        if (!form.contacts || !form.firstname || !form.messageContent) {
            return dispatch(
                togleGlobalModal({
                    isOpen: 'open',
                    text: 'All fields are required.',
                    btnText: 'Got it',
                }),
            );
        }
        if (!validateContacts(form.contacts)) {
            return setValidation((prev) => ({ ...prev, validationContacts: true }));
        }
        if (!validateName(form.firstname)) {
            return setValidation((prev) => ({ ...prev, validationName: true }));
        }
        if (!validateMessage(form.messageContent)) {
            return setValidation((prev) => ({ ...prev, validationMessage: true }));
        }
        postRequest('api/v3/fraud/affiliate', {
            contacts: form.contacts,
            name: form.firstname,
            message_content: form.messageContent,
        }).then((res: any) => {
            if (res?.status) {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: 'Thank you for your information!',
                        btnText: 'Got it',
                    }),
                );
            } else {
                dispatch(
                    togleGlobalModal({
                        isOpen: 'open',
                        text: 'Something went wrong!',
                        btnText: 'Got it',
                    }),
                );
            }
        });
        closeModal();
    };

    return {
        onSubmit,
        form,
        validation,
        handleInput,
        closeModal,
    };
}
