import * as Sentry from '@sentry/react';
import { getApiInstance } from './apiClient';
import { isSentryInitialized } from 'utils/sentryInit';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { sendErrToSentry } from './common';
import { localStorageGetItem } from './local-storage';
import { v4 as uuidv4 } from 'uuid';
import { actionCookies } from 'utils/common';

interface AxiosBaseConfig extends AxiosRequestConfig {
    startTime?: number;
    headers: {
        Authorization: string;
        'X-Request-ID': string;
        fingerprintHash: string;
        'cf-ray'?: string;
    };
}

const BASE_URL = process.env.REACT_APP_BACKEND_URL || window.location.origin + '/';
const url = BASE_URL + 'api';

const getUrl = (path: string) => {
    return `${url}/${path}`;
};

const getTimeout = (startTime: number) => {
    try {
        return Math.round((new Date().getTime() - startTime) / 1000) + ' sec';
    } catch (err) {
        return null;
    }
};

const getUserInfo = () => {
    return localStorageGetItem('token', true);
};

const getXRequestID = () => {
    let xRequestID;
    let uuid;

    try {
        xRequestID = localStorageGetItem('X-Request-ID', true) || '0';
    } catch (err) {
        xRequestID = '0';
    }

    try {
        uuid = uuidv4() || '';
    } catch (err) {
        uuid = '';
    }

    return xRequestID + uuid;
};

const getUserFingerprint = () => {
    const fingerprintHash = localStorageGetItem('fingerprintHash');
    return fingerprintHash === 'null' ? '' : fingerprintHash || '';
};

const getAuthHeader = (customHeaders = {}) => {
    return {
        headers: {
            Authorization: `Bearer ${getUserInfo() || ''}`,
            'X-Request-ID': `${getXRequestID()}`,
            fingerprintHash: `${getUserFingerprint() || ''}`,
            ...customHeaders,
        },
    };
};

const botRequest = async (
    status: number,
    message: string,
    headers: AxiosResponse['headers'],
    headersReq: AxiosBaseConfig,
) => {
    if (!window.origin?.includes('stage') && +status >= 500 && +status < 600 && +status !== 501) {
        const traceData = await axios
            .get(`${window.location.origin}/cdn-cgi/trace`)
            .then((res) => res?.data)
            .catch(() => null);

        const cfRay = headers?.['cf-ray'] || '';
        if (!cfRay) return;

        const externalID = localStorageGetItem('external_id');
        const xRequestID = headersReq?.headers?.['X-Request-ID'] || '';
        const traceMessage = traceData ? traceData?.toString()?.replaceAll('=', ': ') : '';
        const messageData = `${message}\nExternalID: ${externalID}\n\nX-Request-ID: ${xRequestID}\nCf-Ray: ${cfRay}\n${traceMessage}`;

        const extraData = {
            externalID: externalID,
            xRequestID: xRequestID,
            cfRay: cfRay,
            traceMessage: traceMessage,
        };
        sendErrToSentry(new Error(message), status, extraData);
        await axios.post(getUrl(`sent/500x_error`), { message: messageData }).catch(() => null);
    }
};

export const getRequest = (url: string) => {
    const api = getApiInstance();
    const headers = getAuthHeader();

    return new Promise((resolve, reject) => {
        api.get(url)
            .then((res: any) => {
                const startTime = res?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nResponse: ${res?.status},\nMethod: 'GET',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(res?.status, message, res?.headers, headers);

                resolve(res.data);
            })
            .catch((error: any) => {
                const status = error?.response?.status ?? 0;
                const startTime = error?.response?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nResponse: ${error},\nMethod: 'GET',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(status, message, error?.response?.headers, headers);

                if (isSentryInitialized && +status >= 500 && +status < 600) {
                    Sentry.captureException(error);
                }
                return reject(error);
            });
    });
};

export const postRequest = (url: string, REQ_BODY: any) => {
    const api = getApiInstance();
    const headers = getAuthHeader();

    return new Promise((resolve, reject) => {
        api.post(url, REQ_BODY)
            .then((res: any) => {
                const startTime = res?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${res?.status},\nMethod: 'POST',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(res?.status, message, res?.headers, headers);
                resolve(res.data);
            })
            .catch((error: any) => {
                const status = error?.response?.status ?? 0;
                const startTime = error?.response?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${error},\nMethod: 'POST',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(status, message, error?.response?.headers, headers);

                if (isSentryInitialized && +status >= 500 && +status < 600) {
                    Sentry.captureException(error);
                }
                return reject(error);
            });
    });
};

export const postRequestWithCustomHeaders = (
    url: string,
    REQ_BODY: any,
    customHeaders?: Record<string, string>,
) => {
    const api = getApiInstance();
    const headers = getAuthHeader(customHeaders);

    const token = headers?.headers?.Authorization?.split(' ').at(1);

    if (!token) {
        headers.headers.Authorization = `Bearer ${actionCookies('token') || localStorage.getItem('token')}`;
    }

    return new Promise((resolve, reject) => {
        api.post(url, REQ_BODY, headers)
            .then((res: any) => {
                const startTime = res?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${res?.status},\nMethod: 'POST',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(res?.status, message, res?.headers, headers);
                resolve(res.data);
            })
            .catch((error: any) => {
                const status = error?.response?.status ?? 0;
                const startTime = error?.response?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${error},\nMethod: 'POST',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(status, message, error?.response?.headers, headers);

                if (isSentryInitialized && +status >= 500 && +status < 600) {
                    Sentry.captureException(error);
                }
                return reject(error);
            });
    });
};

export const deleteRequest = (url: string, REQ_BODY: any) => {
    const api = getApiInstance();
    const headers = getAuthHeader();

    return new Promise((resolve, reject) => {
        api.delete(url, { data: REQ_BODY })
            .then((res: any) => {
                const startTime = res?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nResponse: ${res?.status},\nMethod: 'DELETE',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(res?.status, message, res?.headers, headers);

                resolve(res.data);
            })
            .catch((error: any) => {
                const status = error?.response?.status ?? 0;
                const startTime = error?.response?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nResponse: ${error},\nMethod: 'DELETE',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(status, message, error?.response?.headers, headers);

                if (isSentryInitialized && +status >= 500 && +status < 600) {
                    Sentry.captureException(error);
                }
                return reject(error);
            });
    });
};

export const putRequest = (url: string, REQ_BODY: any) => {
    const api = getApiInstance();
    const headers = getAuthHeader();

    return new Promise((resolve, reject) => {
        api.put(url, REQ_BODY)
            .then((res: any) => {
                const startTime = res?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${res?.status},\nMethod: 'PUT',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(res?.status, message, res?.headers, headers);
                resolve(res.data);
            })
            .catch((error: any) => {
                const status = error?.response?.status ?? 0;
                const startTime = error?.response?.config?.startTime ?? 0;
                const message = `Endpoint: ${getUrl(url)},\nPayload: ${JSON.stringify(REQ_BODY)},\nResponse: ${error},\nMethod: 'PUT',\nTimeout: ${getTimeout(startTime)},\n`;
                botRequest(status, message, error?.response?.headers, headers);

                if (isSentryInitialized && +status >= 500 && +status < 600) {
                    Sentry.captureException(error);
                }
                return reject(error);
            });
    });
};
