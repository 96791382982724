import React, { useEffect, useRef, useState } from 'react';
import styles from './VerifiedProfile.module.scss';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { VerifiedStatus } from './constants';
import { selectUser } from '../../../redux/auth/selectors';
import { openVerificationModal } from '../../../redux/interface/slice';

interface IVerifiedProfile {
    status?: VerifiedStatus | null;
    isProfile?: boolean;
    isSearch?: boolean;
    isLikePage?: boolean;
}

export default function VerifiedProfile({
    status = VerifiedStatus.APPROVED,
    isProfile = false,
    isSearch = false,
    isLikePage = false,
}: IVerifiedProfile) {
    const dispatch = useDispatch();
    const [isOpenTip, setIsOpenTip] = useState(false);
    const statusRef = useRef<HTMLButtonElement>(null);
    const userState = useSelector(selectUser);
    const handleToggleTip = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpenTip((prevState: boolean) => !prevState);
        e.stopPropagation();
    };
    const handleCloseTip = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenTip(false);
    };
    const handleOpenTip = (e: React.PointerEvent<HTMLHeadingElement>) => {
        if (e.pointerType === 'mouse') {
            setIsOpenTip(true);
        }
    };
    const openProfileVerificationModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(openVerificationModal());
    };
    const getContext = () => {
        switch (status) {
            case VerifiedStatus.APPROVED:
                return isProfile ? (
                    <>
                        <h3>Verified account</h3>
                        <p>Your profile has been verified</p>
                    </>
                ) : (
                    <h3>THIS USER HAS BEEN VERIFIED VIA SELFIE</h3>
                );
            case VerifiedStatus.NEED_APPROVE:
                return (
                    <>
                        <h3>Verification pending</h3>
                        <p>
                            {isProfile
                                ? 'Your request is currently under review.'
                                : `The user's request is currently under review.`}
                        </p>
                    </>
                );
            case VerifiedStatus.DECLINED:
                return (
                    <>
                        <h3>Verification rejected</h3>
                        <p>
                            {isProfile
                                ? `Submission didn't meet the criteria.`
                                : `The user's submission didn't meet the criteria.`}
                        </p>
                    </>
                );
            default:
                return (
                    <>
                        <h3>Unverified account</h3>
                        <p>{isProfile ? 'Verify your account.' : 'User has not been verified.'}</p>
                    </>
                );
        }
    };

    const getTitle = () => {
        switch (status) {
            case VerifiedStatus.APPROVED:
                return 'Verified';
            case VerifiedStatus.NEED_APPROVE:
                return 'Verifying';
            case VerifiedStatus.DECLINED:
                return 'Rejected';
            default:
                return 'Unverified';
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (statusRef.current && !statusRef.current.contains(event.target as Node)) {
                setIsOpenTip(false);
            }
        };
        if (isOpenTip) {
            document.addEventListener('pointerdown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
        };
    }, [isOpenTip]);
    if (!userState?.needShowVerify) return null;
    return (
        <>
            <button
                type="button"
                className={`${styles.verified_block} ${isOpenTip ? styles.open : ''}  ${isSearch ? '' : styles.position} ${isLikePage ? styles.top : ''}`}
                ref={statusRef}
                onMouseLeave={handleCloseTip}
            >
                <h2
                    className={`${styles.verified_status} ${styles[status ? status.toLowerCase() : 'unverified']}`}
                    onClick={handleToggleTip}
                    onPointerEnter={handleOpenTip}
                >
                    {isProfile && getTitle()}
                </h2>
                {!(isProfile && userState?.verified === VerifiedStatus.APPROVED) && (
                    <div
                        className={`${styles.verified_tip} ${isSearch ? styles.right : ''} ${isLikePage ? styles.top : ''}`}
                    >
                        {getContext()}
                        {userState?.verified !== VerifiedStatus.APPROVED &&
                            userState?.verified !== VerifiedStatus.NEED_APPROVE && (
                                <div className={styles.verified_tip_buttons_wrap}>
                                    <button
                                        type="button"
                                        className={styles.verified_tip_button}
                                        onClick={handleCloseTip}
                                    >
                                        {isProfile ? `Later` : `I'll do it later`}
                                    </button>
                                    <button
                                        type="button"
                                        className={styles.verified_tip_button}
                                        onClick={openProfileVerificationModal}
                                    >
                                        {isProfile ? `Get verified` : `Verify your profile`}
                                    </button>
                                </div>
                            )}
                    </div>
                )}
            </button>
        </>
    );
}
