import { postRequestWithCustomHeaders } from '../requests';
const { REACT_APP_DATALAYER_TOKEN = '' } = process.env;

export default class DataLayerLogApi {
    constructor() {
        this.universalPath = 'api/v3/conversion-integration';
        this.headers = {
            'x-log-token': REACT_APP_DATALAYER_TOKEN,
        };
    }

    async regularLog(data) {
        const path = `${this.universalPath}/dataLayer-regular-logs`;
        return postRequestWithCustomHeaders(path, data, this.headers).catch(() => null);
    }

    async ecommerceLog(data) {
        const path = `${this.universalPath}/dataLayer-ecommerce-logs`;
        return postRequestWithCustomHeaders(path, data, this.headers).catch(() => null);
    }
}
