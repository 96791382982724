import React from 'react';
import useHandleBonusCredits from './useHandleBonusCredits';
import styles from './ModalBonusCredits.module.scss';
import ModalWindowModern from 'components/atomsKit/ModalWindowModern/ModalWindowModern';
import { Button, CloseButton } from 'components/atomsKit/Elements';

export default function ModalBonusCredits() {
    const { isOpenModal, handleCloseModal, eventId } = useHandleBonusCredits();
    const getContent = () => {
        if (!eventId) return;
        switch (eventId) {
            case 2:
                return (
                    <p className={styles.modal_text}>
                        Hey there! We’ve got something just for you! 🎁
                        <br />
                        🔥<strong> Exclusive 30% discount </strong> on your
                        <strong> 5 purchases </strong>
                        (starting from March 11) – but hurry, this offer expires
                        <strong> at the end of this week!</strong> <br />
                        💎 <strong>Plus, 50 FREE credits </strong> were added to your account on
                        March 11! Use them before they’re gone!
                    </p>
                );
            case 3:
                return (
                    <p className={styles.modal_text}>
                        Hey there! We’ve got something just for you! 🎁
                        <br />
                        🔥<strong> Exclusive 30% discount</strong> on your
                        <strong> 10 purchases </strong>
                        (starting from March 11) – but hurry, this offer expires
                        <strong> at the end of this week!</strong> <br />
                        💎<strong> Plus, 50 FREE credits </strong> were added to your account on
                        March 11! Use them before they’re gone!
                    </p>
                );
        }
    };

    return (
        <ModalWindowModern
            modalIsOpen={isOpenModal}
            closeModal={handleCloseModal}
            hideCloseButton
            zIndex={2000}
        >
            <div className={styles.modal_wrap}>
                <h2 className={styles.modal_title}>
                    🎉 Welcome Back! You've Got a Special Gift! 🎉
                </h2>
                {getContent()}
                <Button
                    className={styles.modal_button}
                    text={'Get Started'}
                    type="button"
                    onClick={handleCloseModal}
                />
                <CloseButton onClick={handleCloseModal} btnClasses={styles.close_btn} />
            </div>
        </ModalWindowModern>
    );
}
