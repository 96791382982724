import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmTerms from './ConfirmTerms/ConfirmTerms';
import ModalWindowModern from 'components/atomsKit/ModalWindowModern/ModalWindowModern';
import { getConfirmTermModalInfo } from '../../../redux/interface/selectors';
import { closeConfirmTermModal } from '../../../redux/interface/slice';

export default function ConfirmTermsModal() {
    const dispatch = useDispatch();
    const { isOpen, bannerData } = useSelector(getConfirmTermModalInfo);
    const handleClose = () => dispatch(closeConfirmTermModal());

    return (
        <>
            <ModalWindowModern
                modalIsOpen={isOpen}
                closeModal={handleClose}
                hideCloseButton
                zIndex={2000}
            >
                {!!(isOpen && bannerData) && (
                    <ConfirmTerms
                        closeConfirmTermModal={handleClose}
                        crossBannersData={bannerData}
                    />
                )}
            </ModalWindowModern>
        </>
    );
}
