import { ASIA_SITES, LATINA_SITES } from '../../../data/mirror';
import { useDispatch } from 'react-redux';
import { openConfirmTermModal } from '../../../redux/interface/slice';
import { postRequest } from '../../../utils/requests';

export interface ICrossBannersData {
    all_banner_click: number;
    delay: number;
    domain: string;
    is_new: boolean;
    log_id: number;
    target_site_id: number;
    time_view: number;
    url: string;
    show_in: {
        like?: number;
        search?: number;
        online_now?: number;
    };
}

type imageCacheItem = string[] | string;
const imageCache: Record<string, imageCacheItem> = {};
let previousType: string | null = null;

export default function useHandleCrossBanner() {
    const dispatch = useDispatch();

    const shuffleArray = (array: string[]) => [...array].sort(() => Math.random() - 0.5);

    const getCachedImages = (log_id: number, nationality: string, type: string): imageCacheItem => {
        const cacheKey = `${log_id}_${type}`;
        if (previousType && previousType !== type) {
            Object.keys(imageCache).forEach((key) => delete imageCache[key]);
        }
        previousType = type;
        const randomVariant = Math.floor(Math.random() * 10) + 1;
        if (imageCache[cacheKey]) {
            return imageCache[cacheKey];
        }
        if (type === 'search') {
            const randomImage = `banner-${type}-${nationality}-${randomVariant}.png`;
            imageCache[cacheKey] = randomImage;
            return randomImage;
        }
        const arrayLength = type === 'like' ? 3 : 5;
        const imageKeys = Array.from(
            { length: arrayLength },
            (_, i) => `banner-${type}-${nationality}-${randomVariant}-${i + 1}.png`,
        );
        imageCache[cacheKey] = shuffleArray(imageKeys);
        return imageKeys;
    };

    const checkNationalitySite = (siteId: number) => {
        if (LATINA_SITES.includes(siteId)) {
            return 'latina';
        } else if (ASIA_SITES.includes(siteId)) {
            return 'asia';
        } else {
            return 'europe';
        }
    };

    const handleBannerClick = (crossBannerInfo: ICrossBannersData) => {
        postRequest(`/api/marketing/click-cross-banner`, { log_id: crossBannerInfo?.log_id });
        dispatch(openConfirmTermModal(crossBannerInfo));
    };

    return {
        checkNationalitySite,
        getCachedImages,
        handleBannerClick,
    };
}
