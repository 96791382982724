import React, { MouseEventHandler, useState } from 'react';
import styles from '../VerificationAlert.module.scss';
import { useSelector } from 'react-redux';
import { getIsOpenVerificationModal } from '../../../../../redux/interface/selectors';
import { Button } from 'components/atomsKit/Elements';

export default function FAQ({
    handleStartVerification,
}: {
    handleStartVerification: MouseEventHandler<HTMLButtonElement>;
}) {
    const [activeIndex, setActiveIndex] = useState<number | null>(0);
    const isProfileIdentification = useSelector(getIsOpenVerificationModal);

    const test_faq_list = [
        {
            title: 'Why should I do this?',
            text: 'To keep your account secure, we need to verify certain high-risk payments as outlined in our Terms and Conditions. Don’t worry—your privacy is our priority. The images you send will only be used to confirm your billing identity and won’t be shared. We’re sorry for any inconvenience this might cause, but if we don’t receive this information, we’ll need to temporarily limit access to your account.',
        },
    ];
    const profile_verification_faq_list = [
        {
            title: 'Building Trust Through Verified Profiles',
            text: 'Verify Profile procedure ensures that profiles on the platform are authentic. Verified profiles give users confidence that they are interacting with real individuals, fostering trust and creating a safer online dating environment. When users see a "verified" badge, they are more likely to engage, knowing the person behind the profile is genuine.',
        },
        {
            title: 'Protecting Users from Bots and Scammers',
            text: 'One of the biggest challenges for dating sites is the presence of bots and fraudulent accounts. These entities can spam users, promote scams, or engage in harmful activities. Verification helps to eliminate these threats by verifying the identity of every user, reducing the chances of bots or malicious actors infiltrating the platform.',
        },
        {
            title: 'Enhancing Platform Credibility',
            text: "A dating site that prioritizes user safety and authenticity stands out in a competitive market. Verification demonstrates the platform's commitment to user well-being, improving its reputation and encouraging more users to join and stay active. Verified profiles have priority in search over unverified.",
        },
        {
            title: 'Creating a Safer Environment for Connections',
            text: 'Verification deters inappropriate or harmful behavior since users know their identity is tied to their account. This fosters a respectful and secure environment, allowing users to focus on building meaningful connections.',
        },
    ];
    const faq_list = isProfileIdentification ? profile_verification_faq_list : test_faq_list;

    const toggleAccordion = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className={styles.faq_block}>
            <h3 className={styles.block_title}>Frequently Asked Questions</h3>
            <ul className={styles.faq_list}>
                {faq_list.map((faq, index) => (
                    <li
                        key={index}
                        className={styles.faq_item}
                        onClick={() => toggleAccordion(index)}
                    >
                        <h4
                            className={`${styles.faq_item_title} ${activeIndex === index ? styles.active_faq : ''}`}
                        >
                            {faq.title}
                        </h4>
                        {activeIndex === index && (
                            <p className={styles.faq_item_text}>{faq.text}</p>
                        )}
                    </li>
                ))}
            </ul>
            <Button
                text="Continue"
                className={styles.block_button_main}
                onClick={handleStartVerification}
            />
        </div>
    );
}
