import DataLayerLogApi from './dataLayeLogApi';
import { sendErrToSentry } from 'utils/common';
import { MIRROR } from 'data/mirrorConfig';
const { REACT_APP_MIRROR_NAME } = process.env;

async function sendLog(type, data) {
    const api = new DataLayerLogApi();

    const method = type === 'regular' ? 'regularLog' : 'ecommerceLog';

    try {
        await api[method](data);
    } catch (error) {
        sendErrToSentry(error);
    }
}

export const sendDataLayerRegularLog = (data, status) => {
    try {
        const siteID = MIRROR[REACT_APP_MIRROR_NAME].SITE_ID;
        const userData = data?.user_data;
        const newData = {
            value: data?.value ?? 0,
            currency: data?.currency ?? 'USD',
            general_data: {
                externalID: Number(userData?.external_id) || 0,
                siteID,
                source: 'gtm',
                event: 'dataLayer',
                conversionType: data?.event ?? 'unknown',
                status,
            },
            user_data: {
                email: userData?.email ?? 'none@none.com',
                first_name: userData?.first_name ?? 'None',
                last_name: userData.last_name ?? 'None',
                gender: userData?.gender ?? 'm',
                dob: userData?.dob ?? 'None',
                country: userData?.country ?? 'XX',
                city: userData?.city ?? 'None',
                external_id: Number(userData?.external_id) || 0,
                transaction_id: userData?.transaction_id ?? 'None',
                new_customer: Boolean(userData?.new_customer) ?? false,
                customer_lifetime_value: userData?.customer_lifetime_value ?? 0,
                ...(userData?.fbp && { fbp: userData?.fbp }),
                ...(userData?.fbc && { fbc: userData?.fbc }),
            },
        };
        sendLog('regular', newData);
    } catch (error) {
        sendErrToSentry(error);
    }
};

export const sendDataLayerEcommerceLog = (data, status, externalID = 0) => {
    try {
        const siteID = MIRROR[REACT_APP_MIRROR_NAME].SITE_ID;
        const newData = {
            general_data: {
                externalID: Number(externalID) || 0,
                siteID,
                source: 'gtm',
                event: 'dataLayer',
                conversionType: 'ecommerce',
                status,
            },
            ecommerce: {
                currencyCode: data?.ecommerce?.currencyCode ?? 'USD',
                impressions: data?.ecommerce?.impressions ?? [],
            },
        };
        sendLog('ecommerce', newData);
    } catch (error) {
        sendErrToSentry(error);
    }
};
