import React from 'react';
import { TextNameonChat } from 'components/atomsKit/Elements';

import cn from 'classnames';

import classes from './CheckV.module.scss';
import {
    LINK_COOKIE,
    LINK_PRIVACY,
    LINK_RETURN_REFUND,
    LINK_STANDARDS,
    LINK_TERMS,
} from '../../../data/config';
import { MIRROR } from '../../../data/mirrorConfig';

interface IProps {
    className?: string;
    label?: string;
    value?: boolean;
    onChange?: () => void;
    error?: boolean;
    openPolicy?: (value: string) => void;
    type?: string;
    domain?: string;
}

const CheckV = ({
    label,
    value,
    onChange,
    className,
    error,
    openPolicy,
    type = '',
    domain = '',
}: IProps) => {
    const getContent = () => {
        switch (type) {
            case 'login':
                return (
                    <div className={classes.first_block_registr_form_policy_text}>
                        <span>
                            {
                                'By clicking “Register now!” or “Sign in with Google” I hereby verify that I am over 18 years of age and have read and agree to the: '
                            }
                        </span>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_TERMS);
                            }}
                        >
                            {'Terms and Conditions, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_PRIVACY);
                            }}
                        >
                            {'Privacy Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_COOKIE);
                            }}
                        >
                            {'Cookies Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_RETURN_REFUND);
                            }}
                        >
                            {'Refund Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_STANDARDS);
                            }}
                        >
                            {'Community Standards '}
                        </div>
                        <span>
                            {`and I also agree to receive any emails relating to 
                        ${
                            MIRROR[process.env.REACT_APP_MIRROR_NAME as keyof typeof MIRROR]
                                ?.NAME_DOMAIN || ''
                        }. Do not sell my personal information`}
                        </span>
                    </div>
                );
            case 'confirm-terms':
                return (
                    <div className={classes.confirm_terms_text}>
                        <span>{'By clicking “Continue” you agree with the: '}</span>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_TERMS);
                            }}
                        >
                            {'Terms and Conditions, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_PRIVACY);
                            }}
                        >
                            {'Privacy Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_COOKIE);
                            }}
                        >
                            {'Cookies Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_RETURN_REFUND);
                            }}
                        >
                            {'Refund Policy, '}
                        </div>
                        <div
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                openPolicy && openPolicy(LINK_STANDARDS);
                            }}
                        >
                            {'Community Standards '}
                        </div>
                        {domain && (
                            <span>{`You also agree to receive any emails relating to ${domain}`}</span>
                        )}
                    </div>
                );
            default:
                return <TextNameonChat text={label ?? ''} className={classes.text} />;
        }
    };
    return (
        <label className={cn(classes.wrap, className)}>
            {onChange !== undefined ? (
                <input
                    type="checkbox"
                    checked={value}
                    onChange={onChange}
                    className={classes.checkbox}
                />
            ) : (
                <input type="checkbox" className={classes.checkbox} defaultChecked={true} />
            )}
            <div
                className={cn(
                    classes.checkBox,
                    value && classes.checkBox_checked,
                    error && classes.checkBox_error,
                )}
            />
            {getContent()}
        </label>
    );
};

export default CheckV;
