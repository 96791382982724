import React, { useState } from 'react';
import styles from '../VerificationAlert.module.scss';
import UploadedPhoto from '../UploadedPhoto';
import { useSelector } from 'react-redux';
import { IUserIdBlockProps } from './index.interface';
import { selectDocs } from '../../../../../redux/auth/selectors';
import { cutLengthString, getReadableFileSizeString } from '../../../../../utils/common';
import { Button, CloseButton } from 'components/atomsKit/Elements';

export default function UserIdBlock({
    handleChangeBlock,
    handleInput,
    deleteUploadedFile,
    isWarning,
    isUserIdFileApproved,
    setIsUserIdFileApproved,
}: IUserIdBlockProps) {
    const docsState = useSelector(selectDocs);
    const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);

    const handleOpenPhoto = () => {
        if (docsState?.userIdFile?.name) {
            setIsOpenPhoto((prev) => !prev);
        }
    };

    const deleteFile = () => {
        setIsUserIdFileApproved(false);
        deleteUploadedFile('userIdFile');
    };

    return (
        <>
            <h3 className={`${styles.block_title} ${styles.user_id_title}`}>
                Take a picture of your valid ID
            </h3>
            <div className={styles.requirements_details}>
                <ul className={styles.requirements_list}>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_correct}`}
                    >
                        Government-issued
                    </li>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_correct}`}
                    >
                        Original full-size, unedited documents
                    </li>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_correct}`}
                    >
                        Readable, well-lit, coloured images
                    </li>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_incorrect}`}
                    >
                        No edited or expired documents
                    </li>
                    <li
                        className={`${styles.requirements_item} ${styles.requirements_item_incorrect}`}
                    >
                        No black and white images
                    </li>
                </ul>
                <ul className={styles.examples_list}>
                    <li className={`${styles.examples_item} ${styles.examples_item_good}`}>Good</li>
                    <li className={`${styles.examples_item} ${styles.examples_item_cut}`}>
                        Not cut
                    </li>
                    <li className={`${styles.examples_item} ${styles.examples_item_blur}`}>
                        Not blurry
                    </li>
                    <li className={`${styles.examples_item} ${styles.examples_item_reflective}`}>
                        Not reflective
                    </li>
                </ul>
            </div>
            <form className={`${styles.form} ${isWarning ? styles.form_error : ''}`}>
                <p
                    className={`${styles.form_info} ${isUserIdFileApproved ? styles.form_info_success : ''}`}
                >
                    {isUserIdFileApproved
                        ? 'The documents have been approved, and there’s no need to re-upload them. However, if you wish to, we will review them again.'
                        : 'File size must be between 10KB and 5120KB in jpg/jpeg/png format.'}
                </p>
                {(docsState?.userIdFile && docsState?.userIdFile?.name) || isUserIdFileApproved ? (
                    <div className={styles.form_file}>
                        <button
                            type="button"
                            disabled={isUserIdFileApproved}
                            onClick={handleOpenPhoto}
                            className={styles.form_file_info}
                        >
                            <p className={styles.form_file_name}>
                                {isUserIdFileApproved
                                    ? 'Previously approved file'
                                    : cutLengthString(docsState?.userIdFile?.name, 30)}
                            </p>
                            {docsState?.userIdFile?.size && (
                                <p
                                    className={styles.form_file_size}
                                >{`(${getReadableFileSizeString(docsState?.userIdFile?.size)})`}</p>
                            )}
                        </button>
                        <CloseButton onClick={deleteFile} btnClasses={styles.form_file_delete} />
                    </div>
                ) : (
                    <>
                        <input
                            type="file"
                            name="userIdFile"
                            id="popup_user_id_input"
                            className={styles.form_input}
                            onChange={handleInput}
                            accept=".jpg, .jpeg, .png"
                            multiple={false}
                        />
                        <label htmlFor="popup_user_id_input" className={styles.form_label}>
                            <span>Upload photo</span>
                        </label>
                    </>
                )}
            </form>
            {isOpenPhoto ? (
                <UploadedPhoto
                    image={docsState?.userIdFile}
                    onClose={() => setIsOpenPhoto(false)}
                />
            ) : null}
            <Button
                text="Continue"
                disabled={!docsState?.userIdFile?.name && !isUserIdFileApproved}
                className={styles.block_button_main}
                onClick={() => handleChangeBlock()}
            />
        </>
    );
}
