import React from 'react';
import useInitAffiliates from 'components/atomsKit/ModalAffiliates/useInitAffiliats';
import ModalWindowModern from 'components/atomsKit/ModalWindowModern/ModalWindowModern';
import { useSelector } from 'react-redux';
import { getIsOpenAffiliatesModal } from '../../../redux/interface/selectors';
import styles from './ModalAffiliates.module.scss';
import { Button, CloseButton } from 'components/atomsKit/Elements';

export default function ModalAffiliates() {
    const isModalOpen = useSelector(getIsOpenAffiliatesModal);

    const { onSubmit, form, validation, handleInput, closeModal } = useInitAffiliates();

    return (
        <ModalWindowModern
            modalIsOpen={isModalOpen}
            closeModal={closeModal}
            hideCloseButton
            zIndex={2000}
        >
            <div className={styles.popup}>
                <h2 className={styles.popup_title}>For Affiliates</h2>
                <p className={styles.popup_text}>To start working with us, please fill form</p>
                <input
                    className={`${styles.popup_input} ${validation.validationContacts ? styles.invalid : ''}`}
                    type="text"
                    name="contacts"
                    required
                    placeholder="Contacts"
                    value={form.contacts}
                    onChange={handleInput}
                />
                <input
                    className={`${styles.popup_input} ${validation.validationName ? styles.invalid : ''}`}
                    type="text"
                    name="firstname"
                    required
                    placeholder="Name"
                    value={form.firstname}
                    onChange={handleInput}
                />
                <textarea
                    className={`${styles.popup_textarea} ${validation.validationMessage ? styles.invalid : ''}`}
                    name="messageContent"
                    value={form.messageContent}
                    onChange={handleInput}
                    placeholder="Your suggestions"
                />
                <Button
                    className={styles.popup_send_btn}
                    text={'Send'}
                    type="button"
                    onClick={onSubmit}
                />
                <CloseButton onClick={closeModal} btnClasses={styles.close_btn} />
            </div>
        </ModalWindowModern>
    );
}
