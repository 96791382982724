import { useEffect, useState } from 'react';
import { postRequest } from '../../../utils/requests';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserEventId } from '../../../redux/auth/selectors';
import { updateUserEventIdStatus } from '../../../redux/auth/slice';

export default function useHandleBonusCredits() {
    const eventId = useSelector(selectUserEventId);
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);

    const handleCloseModal = () => {
        setIsOpenModal(false);
        dispatch(updateUserEventIdStatus());
        if (eventId && eventId > 1) {
            postRequest('api/common/activate-bonus-event', { eventId: eventId });
        }
    };

    useEffect(() => {
        if (eventId && eventId > 1) {
            setIsOpenModal(true);
        }
    }, [eventId]);

    return { handleCloseModal, isOpenModal, eventId };
}
