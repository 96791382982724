import React from 'react';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'components/atomsKit/Elements';
import cn from 'classnames';
import classes from './ModalWindowModern.module.scss';
import PropTypes from 'prop-types';

const ModalWindowModern = ({
    modalIsOpen = false,
    closeModal,
    hideCloseButton = false,
    children,
    offBgBlur = false,
    zIndex = 0,
    showFooterHeder = false,
    shouldCloseOnOverlayClick = false,
}) => {
    const modalRef = useRef();

    useEffect(() => {
        if (modalIsOpen) {
            document.addEventListener('keydown', escFunction);
            document.body.style.overflow = 'hidden';
        } else {
            document.removeEventListener('keydown', escFunction);
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.removeEventListener('keydown', escFunction);
            document.body.style.overflow = 'auto';
        };
    }, [modalIsOpen, showFooterHeder]);

    const escFunction = (e) => {
        if (!shouldCloseOnOverlayClick) return;
        if (e.code === 'Escape') closeModal();
    };

    const handleBg = (e) => {
        if (e.target.id === 'modal') {
            closeModal();
        }
    };

    if (!modalIsOpen) return null;

    return ReactDOM.createPortal(
        <div
            className={cn(
                classes.modalBg,
                offBgBlur && classes.modalBg_offBlur,
                showFooterHeder && classes.modalBg_showFooterHeder,
            )}
            id="modal"
            onClick={handleBg}
            role="dialog"
            aria-modal="true"
            style={{ zIndex: zIndex === 0 ? undefined : zIndex }}
        >
            {!hideCloseButton && (
                <button onClick={closeModal} className={classes.btn}>
                    <Icon icon={'x'} className={classes.icon} />
                </button>
            )}

            <div ref={modalRef} className={classes.children}>
                {children}
            </div>
        </div>,
        document.body,
    );
};

ModalWindowModern.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    hideCloseButton: PropTypes.bool,
    children: PropTypes.node,
    offBgBlur: PropTypes.bool,
    zIndex: PropTypes.number,
    showFooterHeder: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
};

export default ModalWindowModern;
