import { ChangeEvent, useEffect, useState } from 'react';
import * as LENGTH from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { DocsTypes } from './constants';
import { getRequest, postRequest } from '../../../../utils/requests';
import { updateDocsState, updateIsShowStatus } from '../../../../redux/auth/slice';
import { selectDocs } from '../../../../redux/auth/selectors';
import { closeVerificationModal } from '../../../../redux/interface/slice';
import { getIsOpenVerificationModal } from '../../../../redux/interface/selectors';

export default function UseInitVerification(configID: number, banner: string) {
    const dispatch = useDispatch();
    const isOpenVerificationModal = useSelector(getIsOpenVerificationModal);
    const docsState = useSelector(selectDocs);

    const [modalIsActiveIdentityVerification, setModalIsActiveIdentityVerification] =
        useState<boolean>(false);
    const [isWarning, setIsWarning] = useState<boolean>(false);
    const [isDataPassed, setIsDataPassed] = useState<boolean>(false);

    const deleteUploadedFileIdentityVerification = (fieldName: string) => {
        dispatch(updateDocsState({ ...docsState, [fieldName]: {} }));
    };

    const handleInputIdentityVerification = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event?.target;
        const { name } = event.target;

        if (
            (target?.files?.[0]?.size || 0) > LENGTH.MAX_FILE_SIZE ||
            (target?.files?.[0]?.size || 0) < LENGTH.MIN_FILE_SIZE
        ) {
            setIsWarning(true);
            dispatch(updateDocsState({ ...docsState, [name]: {} }));
        } else {
            setIsWarning(false);
            dispatch(updateDocsState({ ...docsState, [name]: target?.files?.[0] }));
        }
    };

    const getIsNeedToVerifyDocs = () => {
        getRequest(`api/v3/user-profile/document`).then((res: any) => {
            if (res?.status) {
                if (res.result.banner === 'IN_PROGRESS_VERIFICATION') {
                    setIsDataPassed(true);
                }
                updateApprovedDocs(res.result);
            }
        });
    };

    const updateApprovedDocs = async (data: any = []) => {
        getRequest(`api/v3/user-profile/document/approved/list`).then((res: any) => {
            if (res?.status) {
                dispatch(
                    updateDocsState({
                        ...docsState,
                        ...data,
                        approvedDocs: res?.result,
                    }),
                );
            } else {
                dispatch(
                    updateDocsState({
                        ...docsState,
                        ...data,
                    }),
                );
            }
        });
    };

    const openIdentityVerificationModal = () => {
        if (banner === 'IN_PROGRESS_VERIFICATION') {
            setIsDataPassed(true);
        }
        updateApprovedDocs().then(() => setModalIsActiveIdentityVerification(true));
    };

    const closeIdentityVerificationModal = () => {
        if (isOpenVerificationModal) {
            dispatch(closeVerificationModal());
        } else {
            setModalIsActiveIdentityVerification(false);
            dispatch(updateIsShowStatus(false));
            postRequest('api/v3/user-profile/document/skip', { configID });
        }
    };

    const onSubmitIdentityVerification = () => {
        if (!docsState.userIdFile?.name && !docsState.userSelfieFile?.name) {
            return closeIdentityVerificationModal();
        }
        const formData = new FormData();
        if (docsState.userIdFile?.name) {
            formData.append('files', docsState.userIdFile as Blob);
            formData.append('types[]', DocsTypes.VALID_ID);
        }
        if (docsState.userSelfieFile?.name) {
            formData.append('files', docsState.userSelfieFile as Blob);
            formData.append('types[]', DocsTypes.SELFIE);
        }
        formData.append('configID', configID?.toString());
        postRequest('api/v3/user-profile/document/save', formData);
        dispatch(updateDocsState({ ...docsState, userIdFile: {}, userSelfieFile: {} }));
        setIsDataPassed(true);
    };

    const onSubmitIdentityVerificationProfile = () => {
        if (!docsState.userOnlySelfieFile?.name) {
            return closeIdentityVerificationModal();
        }
        const formData = new FormData();
        formData.append('files', docsState.userOnlySelfieFile as Blob);
        formData.append('types', DocsTypes.SELFIE_VERIFY);
        postRequest('api/v3/user-profile/document/verification/save', formData);
        dispatch(updateDocsState({ ...docsState, userOnlySelfieFile: {} }));
        setIsDataPassed(true);
    };

    useEffect(() => {
        if (isOpenVerificationModal) {
            updateApprovedDocs().then(() =>
                setModalIsActiveIdentityVerification(isOpenVerificationModal),
            );
        } else {
            setModalIsActiveIdentityVerification(isOpenVerificationModal);
        }
    }, [isOpenVerificationModal]);
    return {
        modalIsActiveIdentityVerification,
        setModalIsActiveIdentityVerification,
        deleteUploadedFileIdentityVerification,
        handleInputIdentityVerification,
        closeIdentityVerificationModal,
        openIdentityVerificationModal,
        onSubmitIdentityVerification,
        onSubmitIdentityVerificationProfile,
        isWarning,
        isDataPassed,
        setIsDataPassed,
        updateApprovedDocs,
        getIsNeedToVerifyDocs,
    };
}
