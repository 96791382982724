import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCookie, sendErrToSentry } from 'utils/common';
import { sendDataLayerRegularLog, sendDataLayerEcommerceLog } from 'utils/dataLayer/dataLayer';
import { checkTwoInt } from 'utils/common';
import { useDetectedDevice } from 'hooks';
import {
    GEO_EVENT_PUSH_V2,
    GEO_TIER1,
    GEO_EVENT_PUSH,
    BLOCKED_COUNTRIES_EVENT,
} from 'data/dictionary';

import { selectUser, selectTail } from 'redux/auth/selectors';

import { getProfileInfo } from 'redux/auth/operations';
import { postRequest } from 'utils/requests';
import { DataLayerStatuses } from 'utils/dataLayer/constants';

export const useGoogleDataLayer = () => {
    const tail = useSelector(selectTail)?.response?.tail;
    const info = useSelector(selectUser)?.user_info;
    const { smallInfo, isNotAndroid, isAndroid } = useDetectedDevice();
    const dataLayer = window.dataLayer;
    const osName = smallInfo().os.toLowerCase();

    const firstName = info?.name?.split(' ')[0];
    const lastName = info?.name?.split(' ')?.[1];
    const dobFormatDDMMYYYY = `${checkTwoInt(info?.b_day || 0)}${checkTwoInt(
        info?.b_month || 0,
    )}${info?.b_year}`;
    const dobFormatYYYYMMDD = `${info?.b_year}${checkTwoInt(info?.b_month || 0)}${checkTwoInt(info?.b_day || 0)}`;
    const genderLetter = !+(info?.gender || 0) ? 'm' : 'f';
    const userAge = +info?.age;

    const isTestEmail = info?.email?.includes('test');
    const isWomanProfile = info?.gender === 1;

    const paymentTotal = info?.payment_total ?? 0;
    const isNewCustomer = paymentTotal === 0;

    const getValue = () => {
        if (!GEO_EVENT_PUSH?.includes(info?.country_code)) return false;

        if (userAge > 34 && userAge < 45) {
            if (osName.includes('android')) {
                return 200;
            }
            if (osName.includes('ios')) {
                return 350;
            }
            if (osName.includes('windows')) {
                return 350;
            }
            if (osName.includes('mac')) {
                return 500;
            }
        } else if (userAge > 44 && userAge < 55) {
            if (osName.includes('android')) {
                return 300;
            }
            if (osName.includes('ios')) {
                return 450;
            }
            if (osName.includes('windows')) {
                return 450;
            }
            if (osName.includes('mac')) {
                return 600;
            }
        } else if (userAge >= 55) {
            if (osName.includes('android')) {
                return 400;
            }
            if (osName.includes('ios')) {
                return 600;
            }
            if (osName.includes('windows')) {
                return 600;
            }
            if (osName.includes('mac')) {
                return 700;
            }
        }
        return false;
    };

    const getValueV2 = () => {
        if (!GEO_EVENT_PUSH?.includes(info?.country_code)) return false;
        const isCountryHighValue = info?.country_code === 'US';
        const isCountryMediumValue = ['GB', 'CA', 'AU'].includes(info?.country_code);

        if (userAge > 44 && userAge < 55) {
            if (isCountryHighValue) {
                if (osName.includes('ios')) {
                    return 450;
                }
                if (osName.includes('windows')) {
                    return 450;
                }
                if (osName.includes('mac')) {
                    return 600;
                }
            } else if (isCountryMediumValue) {
                if (osName.includes('ios')) {
                    return 350;
                }
                if (osName.includes('windows')) {
                    return 350;
                }
                if (osName.includes('mac')) {
                    return 500;
                }
            }
        } else if (userAge >= 55) {
            if (isCountryHighValue) {
                if (osName.includes('ios')) {
                    return 600;
                }
                if (osName.includes('windows')) {
                    return 600;
                }
                if (osName.includes('mac')) {
                    return 700;
                }
            } else if (isCountryMediumValue) {
                if (osName.includes('ios')) {
                    return 500;
                }
                if (osName.includes('windows')) {
                    return 500;
                }
                if (osName.includes('mac')) {
                    return 600;
                }
            }
        }

        return false;
    };

    //  pixel event pixelA12targetOS
    const getValueV3 = ({ count, isRegister }: { count: number; isRegister: boolean }) => {
        const isTargetOs =
            osName.includes('ios') ||
            osName.includes('windows') ||
            osName.includes('mac') ||
            osName.includes('chrome');

        if (isRegister && isTargetOs) {
            if (userAge >= 45 && userAge < 65) {
                return 2.6;
            } else if (userAge >= 65) {
                return 4.0;
            }
        } else if (count === 1) {
            if (userAge >= 45 && userAge < 65) {
                return 400.13;
            } else if (userAge >= 65) {
                return 600.17;
            }
        } else if (count === 3 && userAge >= 45) {
            return 320.15;
        }

        return false;
    };

    //pixel event pixelA12
    const getValueV4 = ({ count, isRegister }: { count: number; isRegister: boolean }) => {
        if (isRegister) {
            if (userAge >= 45 && userAge < 65) {
                return 2.6;
            } else if (userAge >= 65) {
                return 4.0;
            }
        } else if (count === 1) {
            if (userAge >= 45 && userAge < 65) {
                return 400.13;
            } else if (userAge >= 65) {
                return 600.17;
            }
        } else if (count === 3 && userAge >= 45) {
            return 320.15;
        }

        return false;
    };

    const normalizeData = (data: string): string => {
        return data.trim().toLowerCase();
    };

    const hashDataSHA256 = async (data?: string) => {
        if (!data) return '';
        try {
            const buffer = await crypto.subtle.digest(
                'SHA-256',
                new TextEncoder().encode(normalizeData(data)),
            );
            return Array.from(new Uint8Array(buffer))
                .map((byte) => byte.toString(16).padStart(2, '0'))
                .join('');
        } catch (error) {
            sendErrToSentry(error as Error);
            return '';
        }
    };

    const pushEventUniversalPurchase = ({ price, count }: { price: number; count: number }) => {
        if (isTestEmail) return;
        const newData = {
            event: 'universal_purchase',
            value: price,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                transaction_id: `${info?.external_id}${count}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + price,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    const pushEventUniversalPurchase1 = ({ price, count }: { price: number; count: number }) => {
        if (isTestEmail) return;
        const newData = {
            event: 'universal_purchase_1',
            value: price,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                transaction_id: `${info?.external_id}${count}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + price,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    //GT-7414
    const pushEventUniqueUniversalPurchase = ({
        price,
        count,
    }: {
        price: number;
        count: number;
    }) => {
        if (isTestEmail) return;
        //If you purchase the package within 30 days from the date of registration.
        const isWithin30Days =
            moment(new Date()).diff(moment(new Date(info?.created_at)), 'days') <= 30;

        if (GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase()) && isWithin30Days) {
            const newData = {
                event: 'unique_universal_purchase',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + price,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventFirstPurchase = () => {
        const value = 350; //important for marketing
        if (isTestEmail) return;
        const newData = {
            event: 'first_purchase',
            value,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + value,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    // Push DataLayer for FB
    const pushEventFirstPurchaseFB = async () => {
        if (isTestEmail) return;

        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';

        const newData = {
            event: 'first_purchase_fb',
            value: 350, //important for marketing
            currency: 'USD',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: await hashDataSHA256(info?.email),
                first_name: await hashDataSHA256(firstName),
                ...(lastName ? { last_name: await hashDataSHA256(lastName) } : {}),
                gender: await hashDataSHA256(genderLetter), //m or f
                dob: await hashDataSHA256(dobFormatYYYYMMDD),
                country: await hashDataSHA256(info?.country_code), //two letters ISO format
                ...(info?.city_name !== 'Not specified'
                    ? { city: await hashDataSHA256(info?.city_name) }
                    : {}),
                external_id: await hashDataSHA256(`${info?.external_id}`),
            },
        };

        const dataForLayer = {
            event: 'first_purchase_fb',
            value: 350, //important for marketing
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: firstName,
                ...(lastName ? { last_name: lastName } : {}),
                gender: genderLetter,
                dob: dobFormatYYYYMMDD,
                country: info?.country_code,
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: info?.external_id,
                ...(fbp && { fbp }),
                ...(fbc && { fbc }),
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(dataForLayer, DataLayerStatuses.SUCCESS);
        } catch (error) {
            sendErrToSentry(error as Error);
            sendDataLayerRegularLog(dataForLayer, DataLayerStatuses.ERROR);
        }
    };

    const pushEventFirstPurchaseValue = ({ count }: { count: number }) => {
        if (isTestEmail) return;
        const eventValue = getValue();
        const newData = {
            event: 'first_purchase_value',
            value: eventValue,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                transaction_id: `${info?.external_id}${count}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + eventValue,
            },
        };

        try {
            if (eventValue) {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            }
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    const pushEventFirstPurchaseTargetOs = ({ count }: { count: number }) => {
        const eventValue = getValueV2();
        if (!eventValue || isTestEmail) return;
        const newData = {
            event: 'first_purchase_target_os',
            value: eventValue,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                transaction_id: `${info?.external_id}${count}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + eventValue,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    const pushEventSecondPurchase = () => {
        const value = 200;
        if (isTestEmail) return;
        const newData = {
            event: 'second_purchase',
            value, //important for marketing
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + value,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    // Push DataLayer for FB
    const pushEventSecondPurchaseFB = async () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'second_purchase_fb',
            value: 450, //important for marketing
            currency: 'USD',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: await hashDataSHA256(info?.email),
                first_name: await hashDataSHA256(firstName),
                ...(lastName ? { last_name: await hashDataSHA256(lastName) } : {}),
                gender: await hashDataSHA256(genderLetter), //m or f
                dob: await hashDataSHA256(dobFormatYYYYMMDD),
                country: await hashDataSHA256(info?.country_code), //two letters ISO format
                ...(info?.city_name !== 'Not specified'
                    ? { city: await hashDataSHA256(info?.city_name) }
                    : {}),
                external_id: await hashDataSHA256(`${info?.external_id}`),
            },
        };

        const dataForLayer = {
            event: 'second_purchase_fb',
            value: 450, //important for marketing
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: firstName,
                ...(lastName ? { last_name: lastName } : {}),
                gender: genderLetter, //m or f
                dob: dobFormatYYYYMMDD,
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: info?.external_id,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...dataForLayer,
                    user_data: {
                        ...dataForLayer.user_data,
                        ...(fbp && { fbp }),
                        ...(fbc && { fbc }),
                    },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (error) {
            sendErrToSentry(error as Error);
            sendDataLayerRegularLog(
                {
                    ...dataForLayer,
                    user_data: {
                        ...dataForLayer.user_data,
                        ...(fbp && { fbp }),
                        ...(fbc && { fbc }),
                    },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    const pushEventThirdPurchaseValue = ({ price, count }: { price: number; count: number }) => {
        if (isTestEmail) return;
        if (GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())) {
            const newData = {
                event: 'third_purchase_value',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + price,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventTryPay = () => {
        if (isTestEmail) return;
        const newData = {
            event: 'try-pay',
            value: 2.99, //static value
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    // This applies only to bonuses - free credits
    const pushEventFirstCreditSpent = () => {
        if (isTestEmail) return;
        const newData = {
            event: 'first_credit_spent',
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    const pushEventEcommerce = () => {
        if (isTestEmail) return;
        getProfileInfo().then((res) => {
            if (res?.purchase_history) {
                const purchaseMonth = Object.keys(res?.purchase_history)[0];
                const purchaseDay = Object.keys(res?.purchase_history[purchaseMonth])[0];
                const purchaseLast = res?.purchase_history[purchaseMonth][purchaseDay];

                const newData = {
                    ecommerce: {
                        currencyCode: 'USD',
                        impressions: [
                            {
                                name: `${purchaseLast[0]?.amount} credits`,
                                id: purchaseLast[0]?.id,
                                position: purchaseLast[0]?.proposition_id,
                                price: purchaseLast[0]?.price,
                            },
                        ],
                    },
                };

                if (purchaseMonth && purchaseDay && purchaseLast?.length && purchaseLast[0]) {
                    try {
                        dataLayer.push(newData);
                        sendDataLayerEcommerceLog(
                            newData,
                            DataLayerStatuses.SUCCESS,
                            info?.external_id,
                        );
                    } catch (e) {
                        sendErrToSentry(e);
                        sendDataLayerEcommerceLog(
                            newData,
                            DataLayerStatuses.ERROR,

                            info?.external_id,
                        );
                    }
                }
            }
        });
    };

    // // Push DataLayer for FB
    const pushEventPurchase = ({ count }: { count: number }) => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const value = 2.99;
        const newData = {
            event: 'purchase',
            value, //static value
            currency: 'USD',
            order_id: `${info?.external_id}${count}`,
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
                new_customer: isNewCustomer,
                customer_lifetime_value: paymentTotal + value,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // // Push DataLayer for FB
    const pushEventSpendAllCredit = () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'spend_all_credit',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // Push DataLayer for FB
    const pushEventSpendAllCreditFB = async () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'spend_all_credit_fb',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: await hashDataSHA256(info?.email),
                first_name: await hashDataSHA256(firstName),
                ...(lastName ? { last_name: await hashDataSHA256(lastName) } : {}),
                gender: await hashDataSHA256(genderLetter), //m or f
                dob: await hashDataSHA256(dobFormatYYYYMMDD),
                country: await hashDataSHA256(info?.country_code), //two letters ISO format
                ...(info?.city_name !== 'Not specified'
                    ? { city: await hashDataSHA256(info?.city_name) }
                    : {}),
                external_id: await hashDataSHA256(`${info?.external_id}`),
            },
        };

        const dataForLayer = {
            event: 'spend_all_credit_fb',
            user_data: {
                email: info?.email,
                first_name: firstName,
                ...(lastName ? { last_name: lastName } : {}),
                gender: genderLetter, //m or f
                dob: dobFormatYYYYMMDD,
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: info?.external_id,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...dataForLayer,
                    user_data: {
                        ...dataForLayer.user_data,
                        ...(fbp && { fbp }),
                        ...(fbc && { fbc }),
                    },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (error) {
            sendErrToSentry(error as Error);
            sendDataLayerRegularLog(
                {
                    ...dataForLayer,
                    user_data: {
                        ...dataForLayer.user_data,
                        ...(fbp && { fbp }),
                        ...(fbc && { fbc }),
                    },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // // Push DataLayer for FB
    //// ADD THIS PUSH !!!!!!!!
    const pushEventBeginCheckout = () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'begin_checkout',
            value: 2.99, //static value
            currency: 'USD',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // // Push DataLayer for FB
    const pushEventConfirmedEmail = () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'confirmed_email',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // // Push DataLayer for FB
    const pushEventDolphin = () => {
        if (isTestEmail) return;
        const fbp = getCookie('_fbp') || '';
        const fbc = getCookie('_fbc') || '';
        const newData = {
            event: 'dolphin',
            value: 2000.0, //static value
            currency: 'USD',
            ...(fbp && { fbp }),
            ...(fbc && { fbc }),
            user_data: {
                email: info?.email,
                first_name: info?.name?.split(' ')[0],
                ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                gender: genderLetter,
                dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };
        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.SUCCESS,
            );
        } catch (e) {
            sendErrToSentry(e);
            sendDataLayerRegularLog(
                {
                    ...newData,
                    user_data: { ...newData.user_data, ...(fbp && { fbp }), ...(fbc && { fbc }) },
                },
                DataLayerStatuses.ERROR,
            );
        }
    };

    // // Push DataLayer for FB
    const pushEventLead = () => {
        if (isTestEmail) return;
        if (GEO_TIER1?.includes(info?.country_code)) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';
            const newData = {
                event: 'Lead',
                ...(fbp && { fbp }),
                ...(fbc && { fbc }),
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${info?.b_year}${checkTwoInt(info?.b_month)}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };
            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(
                    {
                        ...newData,
                        user_data: {
                            ...newData.user_data,
                            ...(fbp && { fbp }),
                            ...(fbc && { fbc }),
                        },
                    },
                    DataLayerStatuses.SUCCESS,
                );
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(
                    {
                        ...newData,
                        user_data: {
                            ...newData.user_data,
                            ...(fbp && { fbp }),
                            ...(fbc && { fbc }),
                        },
                    },
                    DataLayerStatuses.ERROR,
                );
            }
        }
    };

    // // Push DataLayer for FB
    const pushEventSecondVisit = () => {
        if (isTestEmail) return;
        // If the user entered the site 24 hours after registration, we push an event.

        const isAfter24Hours =
            moment(new Date()).diff(moment(new Date(info?.created_at || '')), 'hours') >= 24;

        if (isAfter24Hours) {
            const secondVisitStore = localStorage.getItem('second_visit');

            if (!secondVisitStore) {
                localStorage.setItem('second_visit', '1');
                postRequest('/api/marketing/checkMarketingConversion', {
                    eventName: 'second_visit',
                }).then((resp: any) => {
                    if (resp?.status && resp?.response?.check) {
                        const fbp = getCookie('_fbp') || '';
                        const fbc = getCookie('_fbc') || '';
                        const newData = {
                            event: 'second_visit',
                            ...(fbp && { fbp }),
                            ...(fbc && { fbc }),
                            user_data: {
                                email: info?.email,
                                first_name: info?.name?.split(' ')[0],
                                ...(info?.name?.split(' ')[1]
                                    ? { last_name: info?.name?.split(' ')[1] }
                                    : {}),
                                gender: genderLetter,
                                dob: `${info?.b_year}${checkTwoInt(
                                    info?.b_month,
                                )}${checkTwoInt(info?.b_day)}`, //birthday format YYYYMMDD
                                country: info?.country_code, //two letters ISO format
                                ...(info?.city_name !== 'Not specified'
                                    ? { city: info?.city_name }
                                    : {}),
                                external_id: `${info?.external_id}`,
                            },
                        };
                        try {
                            dataLayer.push(newData);
                            sendDataLayerRegularLog(
                                {
                                    ...newData,
                                    user_data: {
                                        ...newData.user_data,
                                        ...(fbp && { fbp }),
                                        ...(fbc && { fbc }),
                                    },
                                },
                                DataLayerStatuses.SUCCESS,
                            );
                        } catch (e) {
                            sendErrToSentry(e);
                            sendDataLayerRegularLog(
                                {
                                    ...newData,
                                    user_data: {
                                        ...newData.user_data,
                                        ...(fbp && { fbp }),
                                        ...(fbc && { fbc }),
                                    },
                                },
                                DataLayerStatuses.ERROR,
                            );
                        }
                    }
                });
            }
        }
    };

    const pushEventRegistration = () => {
        if (isTestEmail) return;
        if (GEO_TIER1?.includes(info?.country_code)) {
            const newData = {
                event: 'registration',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    // Push DataLayer for FB
    const pushEventRegistrationFB = async () => {
        if (isTestEmail) return;
        if (GEO_TIER1?.includes(info?.country_code || '')) {
            const fbp = getCookie('_fbp') || '';
            const fbc = getCookie('_fbc') || '';
            const newData = {
                event: 'registration_fb',
                ...(fbp && { fbp }),
                ...(fbc && { fbc }),
                user_data: {
                    email: await hashDataSHA256(info?.email),
                    first_name: await hashDataSHA256(firstName),
                    ...(lastName ? { last_name: await hashDataSHA256(lastName) } : {}),
                    gender: await hashDataSHA256(genderLetter), //m or f
                    dob: await hashDataSHA256(dobFormatYYYYMMDD),
                    country: await hashDataSHA256(info?.country_code), //two letters ISO format
                    ...(info?.city_name !== 'Not specified'
                        ? { city: await hashDataSHA256(info?.city_name) }
                        : {}),
                    external_id: await hashDataSHA256(`${info?.external_id}`),
                },
            };

            const dataForLayer = {
                event: 'registration_fb',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: genderLetter, //m or f
                    dob: dobFormatYYYYMMDD,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: info?.external_id,
                    ...(fbp && { fbp }),
                    ...(fbc && { fbc }),
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(dataForLayer, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error as Error);
                sendDataLayerRegularLog(dataForLayer, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventUserId = () => {
        if (isTestEmail) return;
        try {
            dataLayer.push({
                user_id: info?.external_id,
            });
        } catch (e) {
            sendErrToSentry(e);
        }
    };

    const pushEventTarget45Event = () => {
        if (isTestEmail) return;
        if (
            isNotAndroid &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            const newData = {
                event: 'target45_event',
                value: 0,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventAndroid45Event = () => {
        if (isTestEmail) return;
        if (
            isAndroid &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            const newData = {
                event: 'android45_event',
                value: 0,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventIos45Event = () => {
        if (isTestEmail) return;
        if (
            osName?.includes('ios') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            const newData = {
                event: 'ios45_event',
                value: 0,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventWin45Event = () => {
        if (isTestEmail) return;
        if (
            osName?.includes('windows') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            const newData = {
                event: 'win45_event',
                value: 0,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: info?.name?.split(' ')[0],
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventMac45Event = () => {
        if (isTestEmail) return;
        if (
            osName?.includes('mac') &&
            info?.age >= 45 &&
            tail?.includes('utm_unik=true') &&
            GEO_EVENT_PUSH_V2?.includes(info?.country_code?.toUpperCase())
        ) {
            const newData = {
                event: 'mac45_event',
                value: 0,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(info?.name?.split(' ')[1] ? { last_name: info?.name?.split(' ')[1] } : {}),
                    gender: genderLetter,
                    dob: `${checkTwoInt(info?.b_day)}${checkTwoInt(info?.b_month)}${info?.b_year}`, //birthday format DDMMYYYY
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (e) {
                sendErrToSentry(e);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    //GT-9481
    const pushEventUniversalPurchaseOS = ({ price, count }: { price: number; count: number }) => {
        if (isTestEmail) return;
        const isValidCountry = GEO_EVENT_PUSH.includes(info?.country_code?.toUpperCase() || '');

        if (isNotAndroid && userAge >= 45 && isValidCountry) {
            const newData = {
                event: 'universal_purchase_target_os',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+info?.gender ? 'm' : 'f',
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventFirstPurchaseOs = () => {
        const value = 350; //important for marketing
        if (isTestEmail) return;
        if (isNotAndroid && userAge >= 45) {
            const newData = {
                event: 'first_purchase_os',
                value,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + value,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventSecondPurchaseOs = () => {
        const value = 200; //important for marketing
        if (isTestEmail) return;
        if (isNotAndroid && userAge >= 45) {
            const newData = {
                event: 'second_purchase_os',
                value,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + value,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventThirdPurchaseOs = () => {
        const value = 200; //important for marketing
        if (isTestEmail) return;
        if (isNotAndroid && userAge >= 45) {
            const newData = {
                event: 'third_purchase_os',
                value,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + value,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    // Women Events
    const pushEventSignUpWomen25 = () => {
        if (isTestEmail) return;
        if (
            !BLOCKED_COUNTRIES_EVENT?.includes(info?.country_code || '') &&
            isWomanProfile &&
            userAge >= 25
        ) {
            const newData = {
                event: 'signup_women_25',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatYYYYMMDD,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventFirstPurchaseWomen25 = () => {
        const value = 3; //static value
        if (isTestEmail) return;
        if (
            !BLOCKED_COUNTRIES_EVENT?.includes(info?.country_code || '') &&
            isWomanProfile &&
            userAge >= 25
        ) {
            const newData = {
                event: 'first_purchase_women_25',
                value,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + value,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    const pushEventUniversalPurchaseWomen25 = ({
        price,
        count,
    }: {
        price: number;
        count: number;
    }) => {
        if (isTestEmail) return;
        if (
            !BLOCKED_COUNTRIES_EVENT?.includes(info?.country_code || '') &&
            isWomanProfile &&
            userAge >= 25
        ) {
            const newData = {
                event: 'universal_purchase_women_25',
                value: price,
                currency: 'USD',
                user_data: {
                    email: info?.email,
                    first_name: firstName,
                    ...(lastName ? { last_name: lastName } : {}),
                    gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                    dob: dobFormatDDMMYYYY,
                    country: info?.country_code, //two letters ISO format
                    ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                    external_id: `${info?.external_id}`,
                    transaction_id: `${info?.external_id}${count}`,
                    new_customer: isNewCustomer,
                    customer_lifetime_value: paymentTotal + price,
                },
            };

            try {
                dataLayer.push(newData);
                sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
            } catch (error) {
                sendErrToSentry(error);
                sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
            }
        }
    };

    // pixel event

    const pushEventPixelOs = ({ count = 0, isRegister = false }) => {
        const eventValue = getValueV3({ count, isRegister });
        if (!eventValue || isTestEmail) return;
        const newData = {
            event: 'pixelA12targetOS',
            value: eventValue,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: firstName,
                ...(lastName ? { last_name: lastName } : {}),
                gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                dob: dobFormatDDMMYYYY,
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (error) {
            sendErrToSentry(error);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    const pushEventPixel = ({ count = 0, isRegister = false }) => {
        const eventValue = getValueV4({ count, isRegister });
        if (!eventValue || isTestEmail) return;
        const newData = {
            event: 'pixelA12',
            value: eventValue,
            currency: 'USD',
            user_data: {
                email: info?.email,
                first_name: firstName,
                ...(lastName ? { last_name: lastName } : {}),
                gender: !+(info?.gender || 0) ? 'm' : 'f', //m or f
                dob: dobFormatDDMMYYYY,
                country: info?.country_code, //two letters ISO format
                ...(info?.city_name !== 'Not specified' ? { city: info?.city_name } : {}),
                external_id: `${info?.external_id}`,
            },
        };

        try {
            dataLayer.push(newData);
            sendDataLayerRegularLog(newData, DataLayerStatuses.SUCCESS);
        } catch (error) {
            sendErrToSentry(error);
            sendDataLayerRegularLog(newData, DataLayerStatuses.ERROR);
        }
    };

    return {
        pushEventUniversalPurchase,
        pushEventUniversalPurchase1,
        pushEventUniversalPurchaseOS,
        pushEventUniqueUniversalPurchase,
        pushEventFirstPurchase,
        pushEventPurchase,
        pushEventFirstPurchaseValue,
        pushEventFirstPurchaseTargetOs,
        pushEventSecondPurchase,
        pushEventThirdPurchaseValue,
        pushEventTryPay,
        pushEventFirstCreditSpent,
        pushEventSpendAllCredit,
        pushEventBeginCheckout,
        pushEventConfirmedEmail,
        pushEventEcommerce,
        pushEventDolphin,
        pushEventLead,
        pushEventRegistration,
        pushEventUserId,
        pushEventTarget45Event,
        pushEventAndroid45Event,
        pushEventIos45Event,
        pushEventWin45Event,
        pushEventMac45Event,
        pushEventSecondVisit,
        pushEventFirstPurchaseOs,
        pushEventSecondPurchaseOs,
        pushEventThirdPurchaseOs,
        pushEventSignUpWomen25,
        pushEventFirstPurchaseWomen25,
        pushEventUniversalPurchaseWomen25,
        pushEventPixelOs,
        pushEventPixel,
        pushEventFirstPurchaseFB,
        pushEventSecondPurchaseFB,
        pushEventSpendAllCreditFB,
        pushEventRegistrationFB,
    };
};
