import React, { MouseEventHandler } from 'react';
import styles from '../VerificationAlert.module.scss';
import { useSelector } from 'react-redux';
import { getIsOpenVerificationModal } from '../../../../../redux/interface/selectors';
import { Button } from 'components/atomsKit/Elements';

export default function SuccessBlock({
    onClose,
    openSupportModal,
    handleLogout,
    isBlockClosing,
}: {
    onClose: MouseEventHandler<HTMLButtonElement>;
    openSupportModal: () => void;
    handleLogout: () => void;
    isBlockClosing: boolean;
}) {
    const isProfileIdentification = useSelector(getIsOpenVerificationModal);

    return (
        <>
            <header
                className={`${styles.logout_header} ${isProfileIdentification ? styles.logout_header_static : ''}`}
            >
                {!isProfileIdentification && (
                    <button
                        type="button"
                        className={styles.help_button}
                        onClick={handleLogout}
                        data-testid="email-btn"
                    >
                        <span>Logout</span>
                    </button>
                )}
                <button
                    type="button"
                    className={`${isBlockClosing ? styles.help_button : styles.help_button_with_close} ${isProfileIdentification ? styles.help_button_right : ''}`}
                    onClick={openSupportModal}
                    data-testid="email-btn"
                >
                    <span>Help</span>
                </button>
            </header>
            <h3 className={`${styles.block_title} ${styles.block_title_success}`}>Successfully!</h3>
            <p className={`${styles.block_text} ${styles.block_text_success}`}>
                Your application will be processed within 3 days
            </p>
            {!isBlockClosing && (
                <Button
                    text="Got it"
                    className={styles.block_button_main}
                    onClick={onClose}
                    iconSvg={'icon_success'}
                    color={'#fff'}
                    bgImg
                />
            )}
        </>
    );
}
