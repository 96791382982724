export const getIsOpenFilter = (state) => state.interface.isOpenFilter;
export const getIsOpenAlert = (state) => state.interface.isOpenAlert;
export const getIsOpenVerificationModal = (state) => state.interface.isOpenVerificationModal;
export const getIsOpenAffiliatesModal = (state) => state.interface.isOpenAffiliatesModal;
export const getIsOpenGlobalModal = (state) => state.interface.globalModal.isOpen;
export const getTextGlobalModal = (state) => state.interface.globalModal?.text;
export const getGlobalModalInfo = (state) => state.interface.globalModal;
export const getReFetch = (state) => state.interface.reFetch?.triger;
export const getReFetchAction = (state) => state.interface.reFetch?.action;

export const getChatCount = (state) => state.interface.chatCount;
export const getMailBoxCount = (state) => state.interface.mailBoxCount;

export const getUploadFile = (state) => state.interface.uploadFiles;
export const getUploadLoader = (state) => state.interface.uploadLoader;

export const getTransaction = (state) => state.interface.transaction;
export const getNotification = (state) => state.interface.notification;

export const selectedGifts = (state) => state.interface.selectedGifts;
export const giftFor = (state) => state.interface.giftFor;

export const getModalContent = (state) => state.interface.modalContent;

export const getVideoShow = (state) => state.interface.videoShow;

export const isLoadingInterface = (state) => state.interface.isLoading;

export const getSayHalloList = (state) => state.interface.sayHalloList;

export const getSound = (state) => state.interface.sound;

export const getConfirmTermModalInfo = (state) => state.interface.confirmTermModal;
